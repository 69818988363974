import React,{ Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import duix from 'duix';

// Admin
import HeaderAdmin from './components/HeaderAdmin';
import Admin from './views/Admin';
import AdminProduct from './views/AdminProduct';
import AdminProducts from './views/AdminProducts';
import AdminMedia from './views/AdminMedia';
import AdminCategories from './views/AdminCategories';
import AdminCategory from './views/AdminCategory';
import AdminVariations from './views/AdminVariations';
import AdminStore from './views/AdminStore';
import AdminBanners from './views/AdminBanners';
import AdminPages from './views/AdminPages';
import AdminPage from './views/AdminPage';
import AdminOrders from './views/AdminOrders';
import AdminOrder from './views/AdminOrder';
import AdminSocial from './views/AdminSocial';
import AdminThemes from './views/AdminThemes';
import AdminCommonContent from './views/AdminCommonContent';
import AdminStyle from './views/AdminStyle';
import AdminForum from './views/AdminForum';
import AdminForumSingle from './views/AdminForumSingle';
import AdminDocuments from './views/AdminDocuments';

import Menu from './views/Menu';
import MenuSingle from './views/MenuSingle';
import Posts from './views/Posts';
import PostSingle from './views/PostSingle';
import Pages from './views/Pages';
import PageSingle from './views/PageSingle';
import Banners from './views/Banners';
import Categories from './views/Categories';
import CategorySingle from './views/CategorySingle';
import Users from './views/Users';

import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';




import {Auth} from './components/Auth';
import {Store} from './components/Store';
import Modal from './components/Modal';

import ScrollToTop from 'react-router-scroll-top';
import CookieConsent from "react-cookie-consent";


import {withRouter} from 'react-router-dom';

//import ReactGA from 'react-ga';
import Service from './components/Service';
import AdminAddHtml from './views/AdminAddHtml';

const lsPrefix = 'brntshp'+window.location.hostname.split('.')[0];

// Cart.initCart();

const checkLoginStatus = () => {
    
  if (localStorage.getItem(lsPrefix+'token') && localStorage.getItem(lsPrefix+'userId')) {
    Auth.isAuthenticated = true;
    Auth.token = localStorage.getItem(lsPrefix+'token');
    Auth.userId = localStorage.getItem(lsPrefix+'userId');
    Auth.name = localStorage.getItem(lsPrefix+'name');
    Auth.email = localStorage.getItem(lsPrefix+'email');
    duix.set('registration', 1);
    console.log('chd');
  }else {
      Auth.isAuthenticated = false;
  }
  // currency
  if (localStorage.getItem(lsPrefix+'currency')) {
    Auth.currency = localStorage.getItem(lsPrefix+'currency');
  }
}

const getStore = () =>{
  Service.get('/store/get',{ 
  },(res)=>{
    if(res.success){
      Store.id = res.data.id;
      Store.name =res.data.name;
      Store.description = res.data.description;
      Store.status = res.data.status;
      Store.shippingInfo = res.data.shipping_info;
      Store.lang = res.data.lang;
      Store.langAdmin = res.data.lang_admin;
      Store.createdAt = res.data.created_at;
      Store.updatedAt = res.data.updated_at;
      Store.shippingPriceSerbia = res.data.shipping_price_serbia;
      Store.shippingPriceAfrica = res.data.shipping_price_africa;
      Store.shippingPriceAsia = res.data.shipping_price_asia;
      Store.shippingPriceAntarctica = res.data.shipping_price_antarctica;
      Store.shippingPriceEurope = res.data.shipping_price_europe;
      Store.shippingPriceOceania = res.data.shipping_price_oceania;
      Store.shippingPriceNorthAmerica = res.data.shipping_price_north_america;
      Store.shippingPriceSouthAmerica = res.data.shipping_price_south_america;
      Store.shippingFreeLimit = res.data.shipping_free_limit;
      Store.logo = res.data.logo;
      Store.homeTopHtml = res.data.home_top_html;
      Store.homeBottomHtml = res.data.home_bottom_html;
      Store.footerHtml = res.data.footer_html;

    }
  }); 
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    Auth.isAuthenticated === true
      ? <Component {...props} />
      : <Redirect to={{
          pathname: '/',
          state: { from: props.location }
        }} />
  )} />
)

checkLoginStatus();
getStore();

class App extends Component {

    constructor(props){
        super(props);
        this.header = React.createRef();
        this.state = {
          //stylePath: 'https://api.shop.brainit.rs/getIndexCss?o='+window.location.host+'&u='+Auth.userId, 
          //styleMobilePath: 'https://api.shop.brainit.rs/getMobileCss?o='+window.location.host+'&u='+Auth.userId
          stylePath: Service.api+'/getIndexCss?o='+window.location.host+'&u='+Auth.userId, 
          styleMobilePath: Service.api+'/getMobileCss?o='+window.location.host+'&u='+Auth.userId,
          language: ''
        };
    }

    componentDidMount(){
        checkLoginStatus();
       // ReactGA.initialize(Service.gaCode);
       // ReactGA.pageview(window.location.pathname + window.location.search)
      }

    componentDidUpdate(prevProps) {
        checkLoginStatus();
        if (this.props.location.pathname !== prevProps.location.pathname) {
            console.log('Route change!');
           // ReactGA.initialize(Service.gaCode);
           // ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    componentWillReceiveProps(newProps){
        checkLoginStatus();
        if(this.props != newProps){
            console.log('update app');
           // ReactGA.initialize(Service.gaCode);
           // ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    updateCartDisplay=()=>{
        this.header.current.updateCartDisplay();
    }

    updateRegistration=()=>{
        this.header.current.updateRegistration();
    }

  render() {

    return (
      <div className="wrapper">

        <link rel="stylesheet" type="text/css" href={this.state.stylePath} />
        <link rel="stylesheet" type="text/css" href={this.state.styleMobilePath} />

        <Router>
          <ScrollToTop>
          <Switch>
            {/* Admin */}
            <Route exact path="/" component={Admin} />
            <PrivateRoute path="/menu" component={Menu} />
            <PrivateRoute path="/menu-item/:id?" component={MenuSingle} />
            <PrivateRoute path="/posts/:page?" component={Posts} />
            <PrivateRoute path="/post/:id?" component={PostSingle} />
            <PrivateRoute path="/pages/:page?" component={Pages} />
            <PrivateRoute path="/page/:id?" component={PageSingle} />
            <PrivateRoute path="/banners" component={Banners} />
            <PrivateRoute path="/categories" component={Categories} />
            <PrivateRoute path="/category/:id?" component={CategorySingle} />
            <PrivateRoute path="/users/:page?" component={Users} />

            <PrivateRoute path="/product/:id?" component={AdminProduct} />
            <PrivateRoute path="/products/:page?" component={AdminProducts} />
            <PrivateRoute path="/media" component={AdminMedia} />
            <PrivateRoute path="/categories" component={AdminCategories} />
            <PrivateRoute path="/category/:id?" component={AdminCategory} />
            <PrivateRoute path="/variations" component={AdminVariations} />
            <PrivateRoute path="/store" component={AdminStore} />
            <PrivateRoute path="/pages" component={AdminPages} />
            <PrivateRoute path="/page/:id?" component={AdminPage} />
            <PrivateRoute path="/orders/:page?" component={AdminOrders} />
            <PrivateRoute path="/order/:id?" component={AdminOrder} />
            <PrivateRoute path="/social" component={AdminSocial} />
            <PrivateRoute path="/themes" component={AdminThemes} />
            <PrivateRoute path="/common-content" component={AdminCommonContent} />
            <PrivateRoute path="/add-html" component={AdminAddHtml} />
            <PrivateRoute path="/add-style" component={AdminStyle} />
            <PrivateRoute path="/forum/:page?" component={AdminForum} />
            <PrivateRoute path="/forum-single/:id?" component={AdminForumSingle} />
            <PrivateRoute path="/documents" component={AdminDocuments} />
          </Switch>
          <Modal />
          </ScrollToTop>
          <NotificationContainer/>
        </Router>
      </div>
    );
  }
}

export default withRouter(props => <App {...props}/>);
//export default App;
