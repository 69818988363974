import React, { Component, Fragment } from 'react';
import Service from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';
import {NotificationManager} from 'react-notifications';
import T from '../components/Translate';
import Select from '../components/Select';

const types = [
    {id: 1, text: 'Page'},
    {id: 2, text: 'Link'}
]

class MenuSingle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            option: [],
            content: '',
            selectedType: '',
            pages: [],
            selectedPage: '',
            text: '',
            link: '',
            parentId: ''
        }
    }

    componentDidMount() {
        if(this.props.match.params.id){
            this.getPages();
            this.getMenuItem();
        }
    }

    getMenuItem=()=>{
        Service.get("/menu/get",{},(res) => {
            let item = res.options.find(item => item.id === parseInt(this.props.match.params.id));
            Service.get("/pages/get",{},(res) => {
                if(res.success){
                    this.setState({pages: res.pages},()=>{
                        this.setState({
                            option: item,
                            content: item.content,
                            text: item.text,
                            selectedType: item.type,
                            selectedPage: item.content,
                            link: item.content
                        })
                    })
                }
            });
            
        });
    }
    
    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value});
    }

    setType=(type)=>{
        this.setState({
            selectedType: type,
        })
    }

    getPages=()=>{
        Service.get("/pages/get",{},(res) => {
            if(res.success){
                this.setState({pages: res.pages},()=>{
                })
            }
        });
    }

    updateItem=()=>{
        Service.get("/menu/updateItem",{
            itemId: this.props.match.params.id,
            type: this.state.selectedType,
            text: this.state.text,
            content: this.state.link || this.state.selectedPage
        },(res) => {
            if(res.success){
                alert('Menu item successfuly updated.');
               this.getMenuItem();
            }
        });
    }

    setContent=(res)=>{
        let find = this.state.pages.find(el => el.id === res);
        this.setState({selectedPage: find.slug})
    }

    render() {
        let find = this.state.pages.find(el => el.slug === this.state.option.content);
        
        return(
            <div className="admin">
                <HeaderAdmin />
                <div className="admin-content">

                    {this.state.selectedType == 1 &&
                        <>
                            <label>Text</label>
                            <input type="text" id='text' value={this.state.text} onChange={this.handleInput} />
                            
                            <label>Type</label>
                            <Select placeholder="Select type..." id='id' value='text' selected={this.state.selectedType} options={types} onSelect={(res)=>{this.setType(res)}}/>
                            <label>Page</label>
                            <Select placeholder="Select page..." id='id' value='title' selected={find && find.id} options={this.state.pages} onSelect={(res)=>{this.setContent(res)}}/>
                        </>
                    }
                    
                    {this.state.selectedType == 2 &&
                        <>
                            <label>Text</label>
                            <input type="text" id='text' value={this.state.text} onChange={this.handleInput} />
                            <label>Type</label>
                            <Select placeholder="Select type..." id='id' value='text' selected={this.state.selectedType} options={types} onSelect={(res)=>{this.setType(res)}}/>
                            <label>Link</label>
                            <input type="text" id='link' value={this.state.link} onChange={this.handleInput} />
                        </>
                    }

                    {this.state.selectedType == 0 &&
                        <>
                            <label>Text</label>
                            <input type="text" id='text' value={this.state.text} onChange={this.handleInput} />
                            <label>Type</label>
                            <Select placeholder="Select type..." id='id' value='text' options={types} onSelect={(res)=>{this.setType(res)}}/>
                        </>
                    }

                    <div className="button" onClick={()=>{this.updateItem()}}>Submit</div>
                </div>
            </div>
        );
    }

}

export default MenuSingle;