import React, { Component, Fragment } from 'react';
import Service from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';
import T from '../components/Translate';


class AdminCategories extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            isCreateCategory: false,
            categoryName: '',
            categoryDescription:'',
            selectedCategory: '',
            isCreateSubcategory: false,
            isCreateSubcategory2: false,
            subCategoryName:'',
            subCategoryDescription:'',
            subCategoryName2:'',
            subCategoryDescription2:'',
            selectedSubcategory: ''
        }
    }

    componentDidMount() {
        this.getCategories();
    }

    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value});
    }

    getCategories=()=>{
        Service.get("/categories/get",{},(res) => {
            this.setState({
                categories: res.categories
            })
        });
    }

    isCreateCategory=()=>{
        this.setState({isCreateCategory: !this.state.isCreateCategory})
    }

    createCategory=()=>{
        Service.get("/categories/addCategory",{
            name: this.state.categoryName,
            description: this.state.categoryDescription,
            status: 1,
            parentId: 0
        },(res) => {
            if(res.success){
                this.setState({isCreateCategory: false, categoryName:'', categoryDescription:''},()=>{
                    this.getCategories();
                })
            }
        });
    }

    selectCategory=(categoryId)=>{
        this.setState({selectedCategory: categoryId},()=>{
        })
    }

    isCreateSubcategory=()=>{
        this.setState({isCreateSubcategory: true})
    }

    isCreateSubcategory2=(id)=>{
        this.setState({selectedSubcategory: id, isCreateSubcategory2: true})
    }

    createSubcategory=()=>{
        Service.get("/products/createCategory",{
            name: this.state.subCategoryName,
            description: this.state.subCategoryDescription,
            parentId: this.state.selectedCategory,
            status: 1
        },(res) => {
            if(res.success){
                this.setState({subCategoryName:'', subCategoryDescription:'', isCreateSubcategory: false},()=>{
                    this.getCategories();
                })
            }
        });
    }

    createSubcategory2=()=>{
        Service.get("/products/createCategory",{
            name: this.state.subCategoryName2,
            description: this.state.subCategoryDescription2,
            parentId: this.state.selectedSubcategory,
            status: 1
        },(res) => {
            if(res.success){
                this.setState({subCategoryName2:'', subCategoryDescription2:'', isCreateSubcategory2: false},()=>{
                    this.getCategories();
                })
            }
        });
    }

    removeCategory=(event)=>{
        let categoryId = event.target.getAttribute('data-category-id');

        Service.get("/categories/remove",{
            categoryId: categoryId
        },(res) => {
            this.getCategories();
        });
    }

    changeCategoryStatus=(event)=>{
        console.log(event.target.getAttribute('data-category-id'), 'dpi', event.target.checked);
        Service.get("/categories/setStatus",{
            categoryId: event.target.getAttribute('data-category-id'),
            status: event.target.checked ? 1 : 0
        }, (res) => {
            this.getCategories();
        });
    }
    
    editCategory=(event)=>{
        let categoryId = event.target.getAttribute('data-category-id');
        window.location.href= '/category/'+categoryId;
    }

    addCategory=()=>{
        window.location.href= '/category';
    }

    render() {
        return(
            <div className="admin">
                <HeaderAdmin />
            <div className="admin-content">
                <div className="button" onClick={this.addCategory}><T text="addCategory"/></div>

                <div className="categories-content">
                    {/* <div className="categories-left"> */}
                        <table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th><T text="name"/></th>
                                    <th><T text="description"/></th>
                                    <th><T text="status"/></th>
                                    <th><T text="action"/></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.categories.map(function(item, index){
                                        return(
                                            <tr key={index}>
                                                <td>{item.id}</td>
                                                <td>{item.name}</td>
                                                <td>{item.description}</td>
                                                <td>                                        
                                                    <td><input type="checkbox" data-category-id={item.id} checked={item.status} onChange={this.changeCategoryStatus}/></td>
                                                </td>
                                                <td>
                                                    {/* <div className="button" onClick={()=>this.selectCategory(item.id)}>Podkategorije</div> */}
                                                    {/* <div className="button" data-category-id={item.id} onClick={this.removeCategory}>Izbriši</div> */}
                                                    <div className="button" data-category-id={item.id} onClick={this.editCategory}><T text="edit"/></div>
                                                </td>
                                            </tr>
                                        )
                                    },this)
                                }
                            </tbody>
                        </table>
                    {/* </div> */}

                    {/* Subcategories */}
                    {/* <div className="categories-right">
                        {this.state.selectedCategory &&
                            <div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>#{this.state.selectedCategory}</th>
                                            <th><div className="button" onClick={this.isCreateSubcategory}>Dodaj</div></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.categories.map(function(item, index){
                                        if(item.id === this.state.selectedCategory){

                                        return(
                                            <div>
                                                {item.children &&
                                                    <div>
                                                        {item.children.map(function(child, index){
                                                            return(
                                                                <div>
                                                                    <li key={index}>
                                                                        {child.name}
                                                                        <th><div className="button" onClick={() =>this.isCreateSubcategory2(child.id)}>Dodaj</div></th>
                                                                    </li>

                                                                    {child.children &&
                                                                        <div>
                                                                            {child.children.map(function(child2, index){
                                                                                return(
                                                                                    <li key={index}>
                                                                                        {child2.name}
                                                                                    </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    }
                                
                                                                </div>
                                                            )
                                                        },this)
                                                    }  
                                                    </div>
                                                }

                                            </div>
                                            )}
                                        },this)
                                    }    
                                    </tbody>
                                </table>

                                {this.state.isCreateSubcategory === true &&
                                    <div>
                                        <label htmlFor="subCategoryName">Ime</label>
                                        <input id="subCategoryName" type="text" value={this.state.subCategoryName} onChange={this.handleInput} />
                                        <label htmlFor="subCategoryDescription">Opis</label>
                                        <input id="subCategoryDescription" type="text" value={this.state.subCategoryDescription} onChange={this.handleInput} />
                                        <div className="button" onClick={this.createSubcategory}>Napravi</div>
                                    </div>
                                }

                                {this.state.isCreateSubcategory2 === true &&
                                    <div>
                                        <label htmlFor="subCategoryName2">Ime</label>
                                        <input id="subCategoryName2" type="text" value={this.state.subCategoryName2} onChange={this.handleInput} />
                                        <label htmlFor="subCategoryDescription2">Opis</label>
                                        <input id="subCategoryDescription2" type="text" value={this.state.subCategoryDescription2} onChange={this.handleInput} />
                                        <div className="button" onClick={this.createSubcategory2}>Napravi</div>
                                    </div>
                                }
                            </div>
                        }
                    </div> */}
                </div>
                {/* <ul className="category-list">
                {this.state.categories.map(function(item, index){
                    return(
                        <div>
                            <li className="list-item-1" key={index}>
                                {item.name}
                                <div className="button" onClick={() =>this.addSubcategory(item.id)}>Dodaj podkategoriju</div>
                            </li>

                            {item.children &&
                                <div>
                                    {item.children.map(function(child, index){
                                        return(
                                            <div>
                                                <li className="list-item-2" key={index}>
                                                    {child.name}
                                                </li>

                                                {child.children &&
                                                    <div>
                                                        {child.children.map(function(child2, index){
                                                            return(
                                                                <li className="list-item-3" key={index}>
                                                                    {child2.name}
                                                                </li>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
            
                                            </div>
                                        )
                                    },this)
                                }  
                                </div>
                            }

                        </div>
                    )
                },this)
                }    
                </ul>  */}
            </div>
            </div>
        );
    }

}

export default AdminCategories;