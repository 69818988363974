import React, { Component } from 'react';
import Service from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';
import T from '../components/Translate';

class AdminProducts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            totalProducts: '',
            filter:'',
            perPage: 50,
            page: 1,
            previousPage: 0,
            nextPage: 0,
        }
    }

    componentDidMount(){
        this.getProducts();

        if(this.props.match.params.page){
            this.setState({page: this.props.match.params.page},()=>{
                this.getProducts();
            });
        }else{
            this.getProducts();
        }
    }

    getProducts=()=>{
        Service.get("/products/get",{
            filter: this.state.filter,
            page: this.state.page,
            perPage: this.state.perPage
        }, (res) => {
            if(res.success){
                this.setState({products: res.data.products, totalProducts: res.data.total_products},()=>{
                    this.handlePages();
                });
            }
        });
    }

    editProduct=(productId)=>{
        window.location.href= '/product/'+productId;
    }

    addProduct=()=>{
        window.location.href= '/product';
    }

    handlePages=()=>{
        this.previousPage = parseInt(this.state.page) - 1;
        this.nextPage = parseInt(this.state.page) + 1;

        let totalPages = Math.ceil(this.state.totalProducts / this.state.perPage);

        if(this.nextPage > totalPages){
            this.nextPage = 1;
        }
        if(this.previousPage <= 0){
            this.previousPage = totalPages;
        }

        this.setState({
            previousPage: this.previousPage,
            nextPage: this.nextPage
        })
    }

    handleFilter=(event)=>{
        this.setState({filter: event.target.value},()=>{
            this.getProducts();
        });
    }    

    changeProductStatus=(event)=>{
        console.log(event.target.getAttribute('data-product-id'), 'dpi', event.target.checked);
        Service.get("/products/setStatus",{
            productId: event.target.getAttribute('data-product-id'),
            status: event.target.checked ? 1 : 0
        }, (res) => {
            this.getProducts();
        });
    }

    updatePerPage=(e)=>{
        this.setState({perPage: e.target.value},()=>{
            this.getProducts();
        })
    }

    render() {
        return(
            <div className="admin">
                <HeaderAdmin />
            <div className="admin-content">
                <h1><T text="products"/></h1>
                <div className="button" onClick={this.addProduct}><T text="addProduct"/></div>

                {/* Per page */}
                <div className="per-page-container">
                    <label><T text="perPage"/></label>
                    <select id="perPage" value={this.state.perPage} onChange={(e)=>{this.updatePerPage(e)}}>
                        <option value="10" >10</option>
                        <option value="20" >20</option>
                        <option value="50" >50</option>
                        <option value="100" >100</option>
                    </select>
                </div>
        
                <div className='search-container'><input placeholder="search..." type="text" value={this.state.filter} onChange={this.handleFilter} /></div>
                
               <table>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th><T text="image"/></th>
                            <th><T text="name"/></th>
                            <th><T text="price"/></th>
                            <th><T text="status"/></th>
                            <th><T text="action"/></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.products.map(function(item, index){
                                return(
                                    <tr key={index}>
                                        <td>{item.id}</td>
                                        <td><img src={item.image} width="48" /></td>
                                        <td>{item.name}</td>
                                        <td>{item.price}</td>
                                        <td><input type="checkbox" data-product-id={item.id} checked={item.status} onChange={this.changeProductStatus}/></td>
                                        <td><div className="button" onClick={() =>this.editProduct(item.id)}><T text="edit"/></div></td>
                                    </tr>
                                )
                            },this)
                        }
                    </tbody>
                </table>
                <div className="pagination">
                    <a href={`/products/${this.state.previousPage}`} ><div className="button" style={{marginRight: '.5em'}}>-</div></a>
                    <T text="page"/> {this.state.page} / {Math.ceil(this.state.totalProducts / this.state.perPage)}
                    <a href={`/products/${this.state.nextPage}`} ><div className="button" style={{marginLeft: '.5em'}}>+</div></a>  
                </div>
            </div>

            {/* controls */}
            <div className="admin-controls">

            </div>
            </div>
            
        );
    }

}

export default AdminProducts;