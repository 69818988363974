import React, { Component } from 'react';
import { BrowserRouter as Router, Route, withRouter, Link, Redirect } from "react-router-dom";
import Service from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';
import T from '../components/Translate';
import { NotificationManager } from 'react-notifications';
import AdminQuill from '../components/AdminQuill';


class AdminForum extends Component {

    constructor(props) {
        super(props);
        this.state = {
            forum: [],
            filter: '',
            perPage: 50,
            page: 1,
            previousPage: 0,
            nextPage: 0,
            totalResults: '',
            isAddTopic: false,
            topicTitle: '',
            topicDescription: ''
        }
    }

    componentDidMount() {
        if(this.props.match.params.page){
            this.setState({page: this.props.match.params.page},()=>{
                this.getFprum();
            });
        }else{
            this.getForum();
        }
    }

    getForum=()=>{
        Service.get("/forum/get",{perPage: this.state.perPage, page: this.state.page, filter: this.state.filter},(res) => {
            if(res.success){
                this.setState({forum: res.forum, totalResults: res.total_results},()=>{
                    this.handlePages();
                })
            }
        });
    }

    handlePages=()=>{
        this.previousPage = parseInt(this.state.page) - 1;
        this.nextPage = parseInt(this.state.page) + 1;

        let totalPages = Math.ceil(this.state.totalResults / this.state.perPage);

        if(this.nextPage > totalPages){
            this.nextPage = 1;
        }
        if(this.previousPage <= 0){
            this.previousPage = totalPages;
        }

        this.setState({
            previousPage: this.previousPage,
            nextPage: this.nextPage
        })
    }

    handleFilter=(event)=>{
        this.setState({filter: event.target.value},()=>{
            this.getForum();
        })
    }

    viewTopic=(event)=>{
        let topicId = event.target.getAttribute('data-topic-id');
        window.location.href= '/forum-single/'+topicId;
    }

    // deleteOrder=(event)=>{
    //     let orderId = event.target.getAttribute('data-order-id');
    //     Service.get("/orders/delete",{
    //         orderId: orderId
    //     },(res) => {
    //         if(res.success){
    //             this.getOrders();
    //             NotificationManager.success(<T text="successMessageDeleteOrder"/>)
    //         }
    //     });
    // }

    updatePerPage=(e)=>{
        this.setState({perPage: e.target.value},()=>{
            this.getForum();
        })
    }

    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value});
    }

    setQuillDescription=(data)=>{
        this.setState({topicDescription: data})
    }

    isAddTopic=()=>{
        this.setState({
            isAddTopic: !this.state.isAddTopic
        })
    }

    addTopic=()=>{
        Service.get("/forum/addForumTopic",{
            title : this.state.topicTitle,
            description: this.state.topicDescription,
            status: 0
        },(res) => {
            if(res.success){
                this.setState({isAddTopic: false, topicTitle: '', topicDescription: ''},()=>{
                    this.getForum();
                    NotificationManager.success(<T text="successMessageCreateForumTopic"/>)
                })
            }
        });
    }

    setForumStatus=(event)=>{
        Service.get("/forum/setForumStatus",{
            forumId: event.target.getAttribute('data-topic-id'),
            status: event.target.checked ? 1 : 0

        },(res) => {
            if(res.success){
                this.getForum();
            }
        });
    }

    render() {
        return(
            <div className="admin">
                <HeaderAdmin />
                <div className="admin-content">
                    <h1>Forum</h1>
                    <div className="button" onClick={this.isAddTopic}>Add topic</div>
                    {this.state.isAddTopic &&
                        <div>
                            <label><T text="title"/></label>
                            <input type="text" id="topicTitle" value={this.state.topicTitle} onChange={this.handleInput} />

                            <AdminQuill label={'Decription'} content={ this.state.topicDescription } onChange={this.setQuillDescription}/>

                            <div className="button" onClick={()=>{this.addTopic()}}><T text="submit"/></div>
                        </div>
                    }
                    {/* Per page */}
                    <div className="per-page-container">
                        <label><T text="perPage"/></label>
                        <select id="perPage" value={this.state.perPage} onChange={(e)=>{this.updatePerPage(e)}}>
                            <option value="10" >10</option>
                            <option value="20" >20</option>
                            <option value="50" >50</option>
                            <option value="100" >100</option>
                        </select>
                    </div>
        
                    <div className='search-container'><input placeholder="search..." type="text" value={this.state.filter} onChange={this.handleFilter} /></div>
                    <div className="admin-pages-content">
                            <table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th><T text="title"/></th>
                                        <th><T text="description"/></th>
                                        <th>Created at</th>
                                        <th><T text="status"/></th>
                                        <th><T text="action"/></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.forum.map(function(item, index){
                                            return(
                                                <tr key={index}>
                                                    <td><b><Link to={"/forum-single/" + item.id}>{item.id}</Link></b></td>
                                                    <td>{item.title}</td>
                                                    <td><div dangerouslySetInnerHTML={{__html: `<span>${item.description}</span>`}} /></td>
                                                    <td>{item.created_at}</td>
                                                    <td>                                        
                                                        <td><input type="checkbox" data-topic-id={item.id} checked={item.status} onChange={this.setForumStatus}/></td>
                                                    </td>
                                                    <td>
                                                        <div className="button" data-topic-id={item.id} onClick={this.viewTopic}><T text="details"/></div>
                                                    </td>
                                                </tr>
                                            )
                                        },this)
                                    }
                                </tbody>
                            </table>

                            <div className="pagination">
                                <a href={`/forum/${this.state.previousPage}`} ><div className="button" style={{marginRight: '.5em'}}>-</div></a>
                                <T text="page"/> {this.state.page} / {Math.ceil(this.state.totalResults / this.state.perPage)}
                                <a href={`/forum/${this.state.nextPage}`} ><div className="button" style={{marginLeft: '.5em'}}>+</div></a>  
                            </div>
                    </div>
                    
                </div>
            </div>
        );
    }

}

export default AdminForum;