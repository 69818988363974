import React, { Component } from 'react';
import Service from '../components/ServiceAdmin';
import Select from './Select';

const types = [
    {id: "1", text: 'Page'},
    {id: "2", text: 'Link'}
]

class AddMenuItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedType: '',
            pages: [],
            selectedPage: '',
            text: '',
            link: ''
        }
    }

    componentDidMount() {
        this.getPages();
    }

    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value});
    }

    setType=(type)=>{
        this.setState({
            selectedType: type,
            text: '',
            selectedPage: ''
        })
    }

    getPages=()=>{
        Service.get("/pages/get",{},(res) => {
            if(res.success){
                this.setState({pages: res.pages})
            }
        });
    }

    addItem=()=>{
        //return;
        Service.get("/menu/addItem",{
            type: this.state.selectedType,
            text: this.state.text,
            content: this.state.link || this.state.selectedPage
        },(res) => {
            if(res.success){
                // this.setState({
                //     // type: '',
                //     // pages: res.pages,
                //     // text: '',
                //     // link: '',
                //     // selectedPage: ''
                // })
                this.props.onAddItem();
            }
        });
    }
    
    render() {
        return(
            <div className="admin-content">
                <Select placeholder="Select type..." id='id' value='text' options={types} onSelect={(res)=>{this.setType(res)}}/>

                {this.state.selectedType == 1 &&
                    <>
                        <label>Text</label>
                        <input type="text" id='text' value={this.state.text} onChange={this.handleInput} />
                        
                        <Select placeholder="Select page..." id='id' value='title' options={this.state.pages} selected={this.state.selectedPage} onSelect={(res)=>{this.setState({selectedPage: res})}}/>
                    </>
                }

                {this.state.selectedType == 2 &&
                    <>
                        <label>Text</label>
                        <input type="text" id='text' value={this.state.text} onChange={this.handleInput} />

                        <label>Link</label>
                        <input type="text" id='link' value={this.state.link} onChange={this.handleInput} />
                    </>
                }

                <div className="button" onClick={()=>{this.addItem()}}>Submit</div>
            </div>
        );
    }

}

export default AddMenuItem;