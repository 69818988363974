import React, {useState, useEffect, Fragment} from 'react';
import ServiceAdmin from '../components/ServiceAdmin';
import Service from '../components/Service';
import {Auth} from '../components/Auth';

export default function Document(props) {
    const [document, setDocument] = useState();
    const [documents, setDocuments] = useState([]);
    const [filter, setFilter] = useState('');
    const [label, setLabel] = useState('');

    const getDocuments=()=>{
        ServiceAdmin.get('/documents/get', {filter}, (res)=>{
            if(res.success){
                setDocuments(res.documents);
            }
        })
    }

    useEffect(() => {
        getDocuments();
    }, []);

    const selectDocument = () => {
        props.onSelect(document, label);
    }

    return (
        <div className={props.className}>
            <div className="head">
                <h3>Insert Document</h3>
                <label htmlFor='text'>Link text:</label>
                <input type="text" id='text' value={label} onChange={(e)=>{setLabel(e.target.value)}} />
            </div>   
            <div className="body">
                
                <div className="document-list">
                    {documents.map(item => {
                        return (
                            <div onClick={()=>{setDocument(item)}} className={document === item ? "document document-active" : "document"}>{item}</div>
                        )
                    })}
                </div>
            </div>

            <div className="media-footer">
                <button  onClick={selectDocument} >Submit</button>
                <button className="button-cancel" onClick={props.onCancel}>Cancel</button>
            </div>
        
        </div>
    )
}
