import React, { useEffect, useState } from 'react';
import Service from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';
import { NotificationManager } from 'react-notifications';
import T from '../components/Translate';
import Media from '../components/Media';
import AdminQuill from '../components/AdminQuill';
import CategoriesSelect from '../components/CategoriesSelect';

export default function PageSingle(props){
    const { id, handlePageId } = props.match.params;
    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [featuredImage, setFeaturedImage] = useState('');
    const [contentTop, setContentTop] = useState('');
    const [contentBottom, setContentBottom] = useState('');
    const [status, setStatus] = useState(0);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isMediaFeaturedImage, setIsMediaFeaturedImage] = useState(false);
    const [hasSidebar, setHasSidebar] = useState(false);
    // const [categories, setCategories] = useState('');

    useEffect(()=>{
        if(id){
            getPage();
            setIsUpdate(true);
        }
    },[])
    
    const getPage=()=>{
        Service.get("/pages/get",{pageId: id}, (res) => {
            if(res.success){
                setTitle(res.page.title)
                setSlug(res.page.slug)
                setContentTop(res.page.content_top)
                setContentBottom(res.page.content_bottom)
                setStatus(res.page.status)
                setFeaturedImage(res.page.image)
                setHasSidebar(res.page.has_sidebar ? 1 : 0)
            }
        });
    }

    const handleSidebarChange=()=>{
        setHasSidebar(!hasSidebar);
    }

    const createPage=()=>{
        Service.get("/pages/create",{
            title,
            slug,
            contentTop,
            contentBottom,
            hasSidebar
        },(res) => {
            if(res.success){
                NotificationManager.success(<T text="successMessageCreatePage"/>)
                window.location.href= '/page/'+res.lastInsertedId;
            }
        });
    }

    const updatePage=()=>{
        Service.get("/pages/update",{
            pageId: id,
            title,
            slug,
            contentTop,
            contentBottom,
            hasSidebar: hasSidebar ? 1 : 0
        }, (res) => {
            if(res.success){
                NotificationManager.success(<T text="successMessageUpdatePage"/>)
                getPage();
            }
        });
    }

    const changePageStatus=(e)=>{
        Service.get("/pages/setStatus",{
            pageId: id,
            status: e.target.checked ? 1 : 0
        }, (res) => {
            if(res.success){
                getPage();
            }
        });
    }

    const removePage=()=>{
        Service.get("/pages/setStatus",{
            pageId: id,
            status: 9
        }, (res) => {
            if(res.success){
                if (window.confirm("Are you sure want to delete this page?") == true) {
                    window.location.href= '/pages';
                }
            }
        });
    }

    const setFeaturedImageFunc=(image)=>{
        Service.get('/pages/setImage',{
            pageId: id,
            imageId: image.id
        }, res => {
            if(res.success){
                NotificationManager.success(<T text="successMessageSetPageFeaturedImage"/>)
                getPage();
            }
        })
    }

    // Quill content from component
    const setQuillContentTop=(data)=>{
        setContentTop(data);
    }

    const setQuillContentBottom=(data)=>{
        setContentBottom(data);
    }

    return(
        <div className="admin">
        <HeaderAdmin />
            <div className="admin-content">
                <h1>{title}</h1>

                <label><T text="title"/></label>
                <input type="text" value={title} onChange={(e)=>{setTitle(e.target.value)}} />
                <p className='help'>Page title, visible to users</p>

                <label><T text="slug"/></label>
                <input type="text" value={slug} onChange={(e)=>{setSlug(e.target.value.replace(/[^0-9a-zA-Z\/-\s]/g,'').replace(/\s/g, '').replace(/\//g, ''))}} />
                <p className='help'>html name. Has to be alphabet letters, numbers, with no spaces. Words should be separated with '-' symbol. e.g. (some-cool-page)</p>
                {/* Quill */}
                <AdminQuill label={'Content top'} content={ contentTop } onChange={setQuillContentTop}/>
                <p className='help'>Content Top is the html text that will be displayed <b>before</b> page posts (if page is set to show some posts within a category).</p>

                <AdminQuill label={'Content bottom'} content={ contentBottom } onChange={setQuillContentBottom}/>
                <p className='help'>Content Bottom is the html text that will be displayed <b>after</b> page posts (if page is set to show some posts within a category).</p>
                {!isUpdate &&
                    <div className="button" onClick={()=>{createPage()}}><T text="submit"/></div>
                }

                {isMediaFeaturedImage === true &&
                    <Media className="media-module" onCancel={()=>{setIsMediaFeaturedImage(false)}} onSelect={(image)=>{setFeaturedImageFunc(image); setIsMediaFeaturedImage(false)}} />
                }
            </div>
        

            <div className="admin-controls">
                {isUpdate &&
                    <div>
                        <div className="button" onClick={()=>{updatePage()}}><T text="saveChanges"/></div>

                        <div className="admin-page-active">
                            <input type="checkbox" checked={ status } onChange={(e)=>{changePageStatus(e)}}/>
                            <label><T text="status"/></label>
                        </div>

                        <h3><T text="featuredImage"/></h3>
                        <div className="admin-product-image">
                            <img className="product-thumbnail" src={featuredImage} onClick={()=>{setIsMediaFeaturedImage(true)}}/>
                            <div className="button" onClick={()=>{setIsMediaFeaturedImage(true)}}><T text="changeImage"/></div>
                        </div>
                        <input type="checkbox" checked={hasSidebar} onChange={handleSidebarChange} />has sidebar
                        {/* <p>{hasSidebar.toString()}</p> */}
                        {/* Categories */}
                        <hr />
                        <h3><T text="categories"/> <div className='help'><T text="helpPageCategories"/></div></h3>
                        <CategoriesSelect id={id} source={'pages'}/>

                        <hr />
                        <div className="admin-page-delete">
                            <div className="button" onClick={()=>{removePage()}}><T text="deletePage"/></div>
                        </div> 
                    </div>
                }
            </div>
        </div>
    )
}