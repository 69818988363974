import React, { Component } from 'react';
import Service from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';
import Select from '../components/Select';
import AddMenuItem from '../components/AddMenuItem';
import { ReactComponent as UpArrow } from '../icons/up-arrow-svgrepo-com.svg';
import { ReactComponent as DownArrow } from '../icons/down-arrow-svgrepo-com.svg';
import { ReactComponent as Delete } from '../icons/trash.svg';

var zIndex = 800;

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            options: [],
            isAddItem: false,
        }
    }

    componentDidMount() {
        this.getItems();
    }

    getItems=()=>{
        Service.get("/menu/get",{},(res) => {
            this.setState({
                items: res.items,
                options: res.options
            })
        });
    }

    editMenuItem=(event)=>{
        let itemId = event.target.getAttribute('menu-item-id');
        window.location.href= '/menu-item/'+itemId;
    }

    handleItems=(item)=>{
        zIndex --;
        return(
            <>
                <li style={{zIndex: zIndex }}>
                    {/* {item.items &&
                        <span onClick={()=>{this.setParentOpen(item)}}>{item.open ? '▾' : '▸'}</span>
                    } */}
                    {/* <li> */}
                        <input type="checkbox" checked={item.status} onChange={(e)=>{this.setItemStatus(item.id, e)}}/>
                        {item.text}
                        <img className='move-arrows edit-menu-item' onClick={this.editMenuItem} menu-item-id={item.id} src='edit-icon.png' />
                        <UpArrow className="move-arrows up-arrow" onClick={()=>{this.setItemPosition(0, item.id)}} />
                        <DownArrow className="move-arrows down-arrow" onClick={()=>{this.setItemPosition(1, item.id)}} /> 
                        <div className='menu-select'>
                            <Select placeholder="Subcategory..." id='id' value='text' options={this.state.options.filter(option => option.id !== item.id)} selected={item.parent_id} onSelect={(res)=>{this.setParentItem(item.id, res)}}/>
                        </div>                        
                        <Delete className='move-arrows delete-menu-item' onClick={()=>{this.removeItem(item.id)}} />
                    {/* </li> */}
                </li>
                {item.items &&
                    <ul className="parent-subcategories">
                        {item.items.map((sub) => {
                            return(this.handleItems(sub));
                        })}
                    </ul>
                }
            </>
        )
    }

    setParentOpen=(item)=>{
        if(item.items){
            item.open = !item.open;
            this.handleItems(item)
            this.forceUpdate();
        }
    }

    setParentItem=(id, parentId)=>{
        Service.get("/menu/setParentItem",{
            itemId: id,
            parentId
        },(res) => {
            if(res.success){
                this.getItems();
            }
        });
    }

    setItemPosition=(direction, id)=>{
        Service.get("/menu/moveItem",{
            itemId: id,
            direction
        },(res) => {
            if(res.success){
                this.getItems();
            }
        });
    }

    addItem=()=>{
        this.setState({isAddItem: false},()=>{
            this.getItems();
        })
    }

    removeItem=(id)=>{
        if(!window.confirm('Are you sure that you want to delete this menu item?')){
            return;
        }

        Service.get("/menu/remove",{
            itemId: id,
        },(res) => {
            if(res.success){
                this.getItems();
            }
        });
    }

    setItemStatus=(id, e)=>{
        Service.get("/menu/setStatus",{
            status: e.target.checked ? 1 : 0,
            itemId: id,
        },(res) => {
            if(res.success){
                this.getItems();
            }
        });
    }

    render() {
        return(
            <div className="admin">
                <HeaderAdmin />
                <div className="admin-content">
                    {/* Add item */}
                    <div className="button" onClick={()=>{this.setState({isAddItem: !this.state.isAddItem})}}>Add item</div>
                    {this.state.isAddItem &&
                        <AddMenuItem onAddItem={()=>{this.addItem()}}/>  
                    }

                    {/* Menu items */}
                    <div className="categories-content">
                        <ul className="menu-categories-list">
                            {this.state.items.map(function(item, index){
                                if(item.items.length > 0){
                                    return(this.handleItems(item))
                                }else{
                                    zIndex --;
                                    return(
                                        <li style={{zIndex: zIndex}}>
                                            <input type="checkbox" checked={item.status} onChange={(e)=>{this.setItemStatus(item.id, e)}}/>
                                            <p>{item.text}</p>
                                            <img className='move-arrows edit-menu-item' onClick={this.editMenuItem} menu-item-id={item.id} src='edit-icon.png' />

                                            <UpArrow className="move-arrows up-arrow" onClick={()=>{this.setItemPosition(0, item.id)}} />
                                            <DownArrow className="move-arrows down-arrow" onClick={()=>{this.setItemPosition(1, item.id)}} />
                                            <div className='menu-select'>
                                                <Select placeholder="Subcategory..." id='id' value='text' options={this.state.options.filter(option => option.id !== item.id)} selected={item.parent_id} onSelect={(res)=>{this.setParentItem(item.id, res)}}/>
                                            </div>
                                            <Delete className='move-arrows delete-menu-item' onClick={()=>{this.removeItem(item.id)}} />
                                        </li>
                                    )
                                }  
                            },this)}
                        </ul>                
                    </div>
                </div>
            </div>
        );
    }

}

export default Menu;