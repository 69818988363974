import React, { useEffect, useState, useReducer } from 'react';
import Service from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {NotificationManager} from 'react-notifications';
import T from '../components/Translate';
import Media from '../components/Media';

const initialState = {};

function reducer(state, action) {
    switch (action.type) {
        case action.type:
            {
                return {
                    ...state,
                    [action.type]: action.initialState,
                  };
            }
        default:
            throw new Error();
    }
}

export default function AdminStore(){
    const [store, dispatch] = useReducer(reducer, initialState);
    const [logoUrl, setLogoUrl] = useState('');
    const [isMedia, setIsMedia] = useState(false);
    const [shippingInfo, setShippingInfo] = useState('');

    useEffect(()=>{
        getStore();
        getLogo();
    },[])

    const getStore=()=>{
        Service.get("/store/get",{},(res) => {
            dispatch({type: 'storeName', initialState: res.data.name})
            dispatch({type: 'storeDescription', initialState: res.data.description})
            dispatch({type: 'lang', initialState: res.data.lang})
            dispatch({type: 'adminLang', initialState: res.data.lang_admin})
            dispatch({type: 'shippingPrice', initialState: res.data.shipping_price})
            dispatch({type: 'shippingFreeLimit', initialState: res.data.shipping_free_limit})
            dispatch({type: 'logoId', initialState: res.data.logo})
            dispatch({type: 'contactAddress', initialState: res.data.contact_address})
            dispatch({type: 'contactPhone', initialState: res.data.contact_phone})
            dispatch({type: 'contactPhone2', initialState: res.data.contact_phone_2})
            dispatch({type: 'contactEmail', initialState: res.data.contact_email})
            dispatch({type: 'contactFax', initialState: res.data.contact_fax})
            dispatch({type: 'contactZipCode', initialState: res.data.contact_zip_code})
            dispatch({type: 'contactMunicipality', initialState: res.data.contact_municipality})
            dispatch({type: 'contactCountry', initialState: res.data.contact_country})
            setShippingInfo(res.data.shipping_info)
        });
    }

    const updateStore=()=>{
        Service.get("/store/update",{
            name: store.storeName, 
            description: store.storeDescription,
            lang: store.lang,
            adminLang: store.adminLang,
            shippingPrice: store.shippingPrice,
            shippingFreeLimit: store.shippingFreeLimit,
            contactAddress: store.contactAddress,
            contactZipCode: store.contactZipCode,
            contactMunicipality: store.contactMunicipality,
            contactCountry: store.contactCountry,
            contactPhone: store.contactPhone,
            contactPhone2: store.contactPhone2,
            contactEmail: store.contactEmail,
            contactFax: store.contactFax,
            shippingInfo

        },(res) => {
            if(res.success){
                NotificationManager.success(<T text="successMessageUpdateStore"/>)
                getStore();
            }
        });
    }

    const updateStoreValue=(e)=>{
        dispatch({type: e.target.id, initialState: e.target.value})
    }

    const setShippingInfoFunc=(data)=>{
        setShippingInfo(data);
    }

    const getLogo=()=>{
        Service.get("/store/getLogo",{},(res) => {
            if(res.success){
                setLogoUrl(res.image);
            }
        });
    }

    const setLogo=(image)=>{
        Service.get('/store/setLogo',{imageId: image.id}, res => {
            if(res.success){
                NotificationManager.success(<T text="successMessageSetLogoImage"/>)
                getLogo();
            }
        })
    }

    return(
        <div className="admin">
            <HeaderAdmin />
            <div className="admin-content">
                <label><T text="name"/></label>
                <input type="text" id="storeName" value={store.storeName} onChange={(e)=>{updateStoreValue(e)}}/>
                <label><T text="description"/></label>
                <input type="text" id="storeDescription" value={store.storeDescription} onChange={(e)=>{updateStoreValue(e)}}/>
          
                <label><T text="shippingInfo"/></label>
                <ReactQuill 
                    modules={{
                        clipboard: {
                        matchVisual: false,
                        },
                    }}
                    value={shippingInfo}
                    onChange={setShippingInfoFunc}
                />
            
                {/* Shipping / language */}
                <div className="half">
                    <h3><T text="shipping"/></h3>
                    <label><T text="shippingPrices"/></label>
                    <input type="text" id="shippingPrice" value={store.shippingPrice} onChange={(e)=>{updateStoreValue(e)}}/>

                    <label><T text="shippingFreeLimit"/></label>
                    <input type="text" id="shippingFreeLimit" value={store.shippingFreeLimit} onChange={(e)=>{updateStoreValue(e)}}/>
                </div>
                <div className="half">
                    <h3><T text="language"/></h3>
                    <label><T text="language"/></label>
                    <select id="lang" value={store.lang} onChange={(e)=>{updateStoreValue(e)}}>
                        <option value="sr" >Srpski</option>
                        <option value="en" >Engleski</option>
                    </select>

                    <label><T text="adminLanguage"/></label>
                    <select id="adminLang" value={store.adminLang}onChange={(e)=>{updateStoreValue(e)}}>
                        <option value="sr" >Srpski</option>
                        <option value="en" >Engleski</option>
                    </select>
                </div>
                
                {/* Contact */}
                <h3><T text="contactInfo"/></h3>
                <div className="half">
                    <label><T text="contactAddress"/></label>
                    <input type="text" id="contactAddress" value={store.contactAddress} onChange={(e)=>{updateStoreValue(e)}}/>
                    <label><T text="cartZipCode"/></label>
                    <input type="text" id="contactZipCode" value={store.contactZipCode} onChange={(e)=>{updateStoreValue(e)}}/>
                    <label><T text="municipality"/></label>
                    <input type="text" id="contactMunicipality" value={store.contactMunicipality} onChange={(e)=>{updateStoreValue(e)}}/>
                    <label><T text="cartCountry"/></label>
                    <input type="text" id="contactCountry" value={store.contactCountry} onChange={(e)=>{updateStoreValue(e)}}/>
                </div>

                <div className="half">
                    <label><T text="contactPhone"/></label>
                    <input type="text" id="contactPhone" value={store.contactPhone} onChange={(e)=>{updateStoreValue(e)}}/>
                    <label><T text="contactPhone2"/></label>
                    <input type="text" id="contactPhone2" value={store.contactPhone2} onChange={(e)=>{updateStoreValue(e)}}/>
                    <label><T text="contactEmail"/></label>
                    <input type="text" id="contactEmail" value={store.contactEmail} onChange={(e)=>{updateStoreValue(e)}}/>
                    <label>Fax</label>
                    <input type="text" id="contactFax" value={store.contactFax} onChange={(e)=>{updateStoreValue(e)}}/>
                </div>
            </div>
            
            {/* Media popup */}
            {isMedia &&
                <Media className="media-module" onCancel={()=>{setIsMedia(false)}} onSelect={(image)=>{setLogo(image); setIsMedia(false)}} />
            }

            {/* Admin controls */}
            <div className="admin-controls">
                <div className="button" onClick={()=>{updateStore()}}><T text="saveChanges"/></div>

                <h3><T text="logo"/></h3>
                <div className="admin-product-image">
                    <img className="product-thumbnail" src={logoUrl} onClick={()=>{setIsMedia(true)}}/>
                    <div className="button" onClick={()=>{setIsMedia(true)}}><T text="changeImage"/></div>
                </div>
                <hr />
            </div>
        </div>
    )
}
