import React, { Component, Fragment } from 'react';
import Service from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';
import T from '../components/Translate';


class AdminMedia extends Component {

    constructor(props) {
        super(props);
        this.state = {
            uploadPercent: 0,
            uploaded: 0,
            media: []
        }
    }

    componentDidMount() {
        this.getMedia();
    }

    getMedia=()=>{
        Service.get("/media/get",{}, (res) => {
            if(res.success){
                this.setState({media: res.media})
            }
        });
    }

    // upload = (event) => {
    //     const files = Array.from(event.target.files)
    //     this.setState({ uploading: true })
    
    //     const formData = new FormData()
    
    //     files.forEach((file, i) => {
    //         // formData.append(i, file);
    //     })
    //     formData.append('file', files[0]);
        
    //     const updateProgress = (l, t) => {
    //         this.setState({uploaded: l, max: t, uploadPercent: l/t})
    //         if(l >= t){
    //             request.upload.removeEventListener("progress", _listener);
    //             setTimeout(()=>{
    //                 this.getMedia();
    //             }, 500);            
    //         }
    //     }
    
    //     var _listener;
    
    //     console.log(formData);
    
    //     var request = new XMLHttpRequest();
    //     request.addEventListener("load", ()=>{
    //         console.log("upload done");
    //         this.setState({uploaded: 0});
    //     });
    
    //     request.open("POST", Service.api+'/media/uploadImages');
        
    //     request.upload.addEventListener("progress", function _listener(evt){
    //         if (evt.lengthComputable) {
    //             updateProgress(evt.loaded, evt.total);
    //         }
    //     }, false);
    
    //     request.send(formData);
    // }

    upload = (event) => {
        const files = Array.from(event.target.files)
        console.log("files", files)
        // setLoader(true);
        const formData = new FormData()
        files.forEach((file, i) => {
            formData.append(i, file)
        })
        console.log('formData:', formData);
        Service.uploadImage(formData,(res)=>{
            //this.setState({image: res.data});
            //setImage(res.data);
            this.getMedia();
            // setLoader(false);
        });
    }
    
    deleteMediaImage=(event)=>{
        if(window.confirm('delete image?')){
            let fileName = event.target.getAttribute('data-file-name');
            let imageId = event.target.getAttribute('data-image-id');
            Service.get('/media/deleteMediaImage',{imageId, fileName},(res)=>{
                if(res.success){
                    this.getMedia();
                }else if(!res.success){
                    alert(res.info)
                }
            })
        }
    }

    render() {
        return(
            <div className="admin">
                <HeaderAdmin />
                <div className="admin-content">
                    <div className="button" onClick={()=>{this.uploadInput.click()}} >
                        <span><T text="uploadMedia"/></span>
                        {this.state.uploaded > 0 &&
                            <progress  value={this.state.uploaded} max={this.state.max}></progress> 
                        }
                    </div>
                    <input ref={(ref) => this.uploadInput = ref} style={{display: 'none'}} type='file' id='multi' name="upload[]" onChange={this.upload} multiple data-buttontext={"Upload File"} />
                    <div className="admin-media">
                    {this.state.media.map(function(item, index){
                            return(
                                <div className="admin-media-gallery-image-container">
                                    {/* {item.thumbnail ? 
                                        <img className="admin-media-img" src={item.thumbnail}  alt={item.image}/>
                                        :
                                        <img className="admin-media-img" src={item.url}  alt={item.image}/>
                                    } */}
                                    <img className="admin-media-img" src={item.url}  alt={item.image}/>

                                    <img data-image-id={item.id} data-file-name={item.file_name} onClick={this.deleteMediaImage} className="media-gallery-delete" src="/images/trash.png" />

                                </div>
                            )
                        },this)
                    }
                    </div>
                </div>
            </div>
        );
    }

}

export default AdminMedia;