import React, { useEffect, useState } from 'react';
import HeaderAdmin from '../components/HeaderAdmin';
import ServiceAdmin from '../components/ServiceAdmin';
import {NotificationManager} from 'react-notifications';
import T from '../components/Translate';
import AceEditor from "react-ace";

import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/snippets/python";
import "ace-builds/src-noconflict/theme-github";

const locations = [
    {name: 'home top', location: 'home_top_html'}, 
    {name: 'home bottom', location: 'home_bottom_html'},
    {name: 'footer', location: 'footer_html'}, 
];

export default function AdminAddHtml () {
    const [code, setCode] = useState('');
    const [location, setLocation] = useState('home_top_html');

    useEffect(()=>{
        getHtml();
    },[location])

    const setHtml=()=>{
        ServiceAdmin.get("/store/setHtml",{
            location: location,
            content: code
        },(res) => {
            if(res.success){
                NotificationManager.success(<T text="successMessageSetHtml"/>)
                getHtml();
            }
        });
    }

    const getHtml=()=>{
        ServiceAdmin.get("/store/get",{},(res) => {
            if(res.success){
                if(res.data[location]){
                    setCode(res.data[location]);
                }else{
                    setCode('')
                }
            }
        });
    }

    return (
        <div className="admin">
            <HeaderAdmin />
            <div className="admin-content">
                <h1>Dodatni HTML</h1>
                {locations.map(item =>{
                    return(
                        <div className={location === item.location ? 'html-location-button active': 'html-location-button'} onClick={()=>{setLocation(item.location)}}>{item.name}</div>
                    )
                })}

                <AceEditor
                    value={code}
                    mode="html"
                    theme="github"
                    onChange={(code) => setCode(code)}
                    name="UNIQUE_ID_OF_DIV"
                    editorProps={{ $blockScrolling: true }}
                    enableBasicAutocompletion={true}
                    enableLiveAutocompletion={true}
                    enableSnippets={true}
                />

                <div className='button' onClick={()=>{setHtml()}}><T text="submit"/></div>
            </div>
        </div>
    )
}
