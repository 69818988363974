import React, { Component, Fragment } from 'react';
import Service from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select from "react-dropdown-select";
import {NotificationManager} from 'react-notifications';
import T from '../components/Translate';

class CategorySingle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: 'Nova kategorija', 
            description: '',
            status: 1,
            parentId: 0,
            isUpdate: false,
            categories: [],
            currentCategoryLabel:''
        }
    }

    componentDidMount() {
        if(this.props.match.params.id){
            this.getCategory();
        }
    }

    getCategory=()=>{
        Service.get("/categories/single",{categoryId: this.props.match.params.id}, (res) => {
            this.setState({
                name: res.data.name,
                description: res.data.description,
                status: res.data.status,
                parentId: res.data.parent_id,
                isUpdate: true
            },()=>{
                this.getCategories();
            })
        });
    }

    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value});
    }

    handleDescription=(value)=>{
        this.setState({ description: value })
    }

    createCategory=()=>{
        Service.get("/categories/addCategory",{
            name: this.state.name,
            description: this.state.description,
            status: this.state.status,
            parentId: this.state.parentId
        },(res) => {
            if(res.success){
                NotificationManager.success(<T text="successMessageCreateCategory"/>)
                window.location.href= '/category/'+res.lastInsertedId;
            }
        });
    }

    updateCategory=()=>{
        Service.get("/categories/updateCategory",{
            categoryId: this.props.match.params.id,
            name: this.state.name,
            description: this.state.description,
        }, (res) => {
            if(res.success){
                NotificationManager.success(<T text="successMessageUpdateCategory"/>)
                this.getCategory();
            }
        });
    }

    changeCategoryStatus=(event)=>{
        Service.get("/categories/setStatus",{
            categoryId: this.props.match.params.id,
            status: event.target.checked ? 1 : 0
        }, (res) => {
            this.getCategory();
        });
    }

    removeCategory=()=>{
        Service.get("/categories/remove",{
            categoryId: this.props.match.params.id
        },(res) => {
            window.location.href= '/categories';
        });
    }

    getCategories=()=>{

        Service.get("/categories/get",{},(res) => {
            let c = [];
            let curCategory = '';
            res.categories.map((item)=>{
                if(item.id !== parseInt(this.props.match.params.id)){
                    c.push({id: item.id, label: item.name});
                }
                if(item.id === this.state.parentId){
                    curCategory = item.name;
                }
            });

            this.setState({categories: c, currentCategoryLabel: curCategory})
        });
        
    }

    selectedParentCategory=(parent)=>{
        if(parent[0].id){
            Service.get("/categories/setParentCategory",{
                parentId: parent[0].id,
                categoryId: this.props.match.params.id
            },(res) => {
                NotificationManager.success(<T text="successMessageParentCategory"/>)
            });
        }
    }
    render() {
        return(
            <div className="admin">
                <HeaderAdmin />
                <div className="admin-content">
                    <h1>{this.state.name}</h1>

                    <label><T text="name"/></label>
                    <input type="text" id='name' value={this.state.name} onChange={this.handleInput} />

                    <label><T text="description"/></label>
                    <ReactQuill 
                        modules={{
                            clipboard: {
                            matchVisual: false,
                            },
                        }}
                        value={this.state.description}
                        onChange={this.handleDescription}  
                    />


                    {this.state.isUpdate === false &&
                        <div className="button" onClick={this.createCategory}><T text="submit"/></div>
                    }

                    {this.state.isUpdate === true &&
                        <div>
                            <div className="button" onClick={this.updateCategory}><T text="submit"/></div>
                        </div>
                    }  
                </div>

                {this.props.match.params.id &&

                    <div className="admin-controls">
                        <div className="button" onClick={this.updateCategory}><T text="saveChanges"/></div>
                        <div className="admin-category-active">
                            <input type="checkbox" checked={this.state.status} onChange={this.changeCategoryStatus}/>
                            <label><T text="status"/></label>
                        </div>
                        <div className="admin-category-delete">
                            <div className="button" onClick={this.removeCategory}><T text="deleteCategory"/></div>
                        </div>
                        <div className="admin-category-select">
                            <label><T text="putUnder"/>:</label>
                            <Select 
                                options={this.state.categories} 
                                valueField="id"
                                labelField="label"
                                searchBy="label"
                                values={[{
                                    label: this.state.currentCategoryLabel
                                }]}
                                onChange={(value) =>
                                    this.selectedParentCategory(value)
                                }
                            />
                        </div>
                    </div>
                }
            </div>
        );
    }

}

export default CategorySingle;