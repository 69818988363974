import React, { Component, Fragment } from 'react';
import Service from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';
import T from '../components/Translate';


class Categories extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            isCreateCategory: false,
            categoryName: '',
            categoryDescription:'',
            selectedCategory: '',
            isCreateSubcategory: false,
            isCreateSubcategory2: false,
            subCategoryName:'',
            subCategoryDescription:'',
            subCategoryName2:'',
            subCategoryDescription2:'',
            selectedSubcategory: ''
        }
    }

    componentDidMount() {
        this.getCategories();
    }

    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value});
    }

    getCategories=()=>{
        Service.get("/categories/get",{},(res) => {
            this.setState({
                categories: res.categories
            })
        });
    }

    isCreateCategory=()=>{
        this.setState({isCreateCategory: !this.state.isCreateCategory})
    }

    createCategory=()=>{
        Service.get("/categories/addCategory",{
            name: this.state.categoryName,
            description: this.state.categoryDescription,
            status: 1,
            parentId: 0
        },(res) => {
            if(res.success){
                this.setState({isCreateCategory: false, categoryName:'', categoryDescription:''},()=>{
                    this.getCategories();
                })
            }
        });
    }

    selectCategory=(categoryId)=>{
        this.setState({selectedCategory: categoryId},()=>{
        })
    }

    isCreateSubcategory=()=>{
        this.setState({isCreateSubcategory: true})
    }

    isCreateSubcategory2=(id)=>{
        this.setState({selectedSubcategory: id, isCreateSubcategory2: true})
    }

    createSubcategory=()=>{
        Service.get("/products/createCategory",{
            name: this.state.subCategoryName,
            description: this.state.subCategoryDescription,
            parentId: this.state.selectedCategory,
            status: 1
        },(res) => {
            if(res.success){
                this.setState({subCategoryName:'', subCategoryDescription:'', isCreateSubcategory: false},()=>{
                    this.getCategories();
                })
            }
        });
    }

    createSubcategory2=()=>{
        Service.get("/products/createCategory",{
            name: this.state.subCategoryName2,
            description: this.state.subCategoryDescription2,
            parentId: this.state.selectedSubcategory,
            status: 1
        },(res) => {
            if(res.success){
                this.setState({subCategoryName2:'', subCategoryDescription2:'', isCreateSubcategory2: false},()=>{
                    this.getCategories();
                })
            }
        });
    }

    removeCategory=(event)=>{
        let categoryId = event.target.getAttribute('data-category-id');

        Service.get("/categories/remove",{
            categoryId: categoryId
        },(res) => {
            this.getCategories();
        });
    }

    changeCategoryStatus=(event)=>{
        console.log(event.target.getAttribute('data-category-id'), 'dpi', event.target.checked);
        Service.get("/categories/setStatus",{
            categoryId: event.target.getAttribute('data-category-id'),
            status: event.target.checked ? 1 : 0
        }, (res) => {
            this.getCategories();
        });
    }
    
    editCategory=(event)=>{
        let categoryId = event.target.getAttribute('data-category-id');
        window.location.href= '/category/'+categoryId;
    }

    addCategory=()=>{
        window.location.href= '/category';
    }

    render() {
        return(
            <div className="admin">
                <HeaderAdmin />
            <div className="admin-content">
                <div className="button" onClick={this.addCategory}><T text="addCategory"/></div>

                <div className="categories-content">
                        <table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th><T text="name"/></th>
                                    <th><T text="description"/></th>
                                    <th><T text="status"/></th>
                                    <th><T text="action"/></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.categories.map(function(item, index){
                                        return(
                                            <tr key={index}>
                                                <td>{item.id}</td>
                                                <td>{item.name}</td>
                                                <td>{item.description}</td>
                                                <td>                                        
                                                    <td><input type="checkbox" data-category-id={item.id} checked={item.status} onChange={this.changeCategoryStatus}/></td>
                                                </td>
                                                <td>
                                                    <div className="button" data-category-id={item.id} onClick={this.editCategory}><T text="edit"/></div>
                                                </td>
                                            </tr>
                                        )
                                    },this)
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

}

export default Categories;