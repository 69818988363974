import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Media from '../components/Media';
import T from '../components/Translate';
import Document from '../components/Document';

function getVideoId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
}

class AdminQuill extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: '',
            isQuillUpdate: false,
            isYoutube: false,
            isDocumentUpdate: false,
            youtubeLink: '',
            youtubeCursorPosition: 0,
            documentCursorPosition: 0,
            label: '',
            modules: {
                clipboard: {
                    matchVisual: false,
                },
                toolbar: {
                    container: [
                        [{ 'header': [false, 2, 3] }],
                        [{ 'align': [] }],
                        ['bold', 'italic', 'underline',],
                        [{'list': 'ordered'}, {'list': 'bullet'}],
                        [{ 'indent': '-1'}, { 'indent': '+1' }],
                        [{ 'script': 'super' }, 'strike'],
                        [{ 'color': [] }, { 'background': [] }], 
                        ['customImage'],
                        ['customYoutubeVideo'],
                        ['link'],
                        ['document']
                    ],
                    handlers: {
                        'customImage': ()=>{this.isQuillUpdate()},
                        'customYoutubeVideo': ()=>{this.isYoutube()},
                        'document': ()=>{this.isDocumentUpdate()}
                    }
                }
            }
        }

        this.handleContent = this.handleContent.bind(this)
        this.setQuillImage = this.setQuillImage.bind(this)
        this.setYoutubeVideo = this.setYoutubeVideo.bind(this)
        this.setDocument = this.setDocument.bind(this)

        this.formats = [
            'header',
            'align',
            'bold', 'italic', 'underline', 
            'list', 'bullet',
            'indent', 'indent',
            'script', 'strike',
            'color', 'background',
            'customImage', 'image',
            'customYoutubeVideo', 'video',
            'link', 'document'
        ];

        this.quillRef = null;
    }

    componentWillReceiveProps(props){
        if(props){
            this.setState({content: props.content})
        }
    }

      
    handleContent=(value,delta, source)=>{
        if (source == 'user' && value != this.props.body) {
            this.setState({ content: value },()=>{
                this.props.onChange(this.state.content)
            })
        }
    }

    isQuillUpdate=()=>{
        this.setState({isQuillUpdate: true})
    }

    isDocumentUpdate=()=>{
        const editor = this.quillRef.getEditor();
        const cursorPosition = editor.getSelection().index;

        this.setState({isDocumentUpdate: true, documentCursorPosition: cursorPosition})
    }

    isYoutube=()=>{
        const editor = this.quillRef.getEditor();
        const cursorPosition = editor.getSelection().index;

        this.setState({isYoutube: true, youtubeCursorPosition: cursorPosition})
    }

    setQuillImage=(image)=>{
        const editor = this.quillRef.getEditor();
        const cursorPosition = editor.getSelection().index;

        this.setState({isQuillUpdate: false},()=>{
            editor.insertEmbed(cursorPosition, 'image', image.url, ReactQuill.Quill.sources.USER);
            editor.setSelection(cursorPosition + 1);
        })
    }

    setDocument = (documentName, label) => {
        const editor = this.quillRef.getEditor();
        this.setState({isQuillUpdate: false},()=>{
            editor.clipboard.dangerouslyPasteHTML(this.state.documentCursorPosition,`<a href='https://vp.pp.cekaonica.com/api/documents/${documentName}' target='_blank'>${label}</a>`, ReactQuill.Quill.sources.USER);
        })
    }

    setYoutubeVideo=()=>{
        let videoId = getVideoId(this.state.youtubeLink);
        const editor = this.quillRef.getEditor();
        this.setState({isYoutube: false, youtubeLink: ''},()=>{
            editor.insertEmbed(this.state.youtubeCursorPosition, 'video', `https://www.youtube.com/embed/${videoId}`, ReactQuill.Quill.sources.USER);
            editor.setSelection(this.state.youtubeCursorPosition + 1);
        })
    }

    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value});
    }

    render() {
        var icons = ReactQuill.Quill.import('ui/icons');
        icons['customImage'] = '<i class="fa fa-picture-o" aria-hidden="true"></i>';
        icons['customYoutubeVideo'] = '<i class="fa fa-video-camera" aria-hidden="true"></i>';
        return(
            <div>
                {this.state.isYoutube &&
                    <div className="youtube-upload-container">
                        <h2>Upload youtube link</h2>
                        <div className="button" style={{position: 'absolute', top: '5px', right: '5px'}} onClick={()=>{this.setState({isYoutube: false})}}><T text="close"/></div>
                        <input type="text" id='youtubeLink' value={this.state.youtubeLink} onChange={this.handleInput} />
                        <div className="button" onClick={()=>{this.setYoutubeVideo()}}><T text="submit"/></div>
                    </div>
                }

                {this.state.isQuillUpdate === true &&
                    <Media className="media-module" onCancel={()=>{this.setState({isQuillUpdate: false})}} onSelect={(image)=>{this.setQuillImage(image); this.setState({isQuillUpdate: false})}} />
                }

                {this.state.isDocumentUpdate &&
                    <Document className="media-module" onCancel={()=>{this.setState({isDocumentUpdate: false})}} onSelect={(documentName, label)=>{this.setDocument(documentName, label == '' ? 'link' : label); this.setState({isDocumentUpdate: false})}} />
                }

                <label>{this.props.label}</label>
                <ReactQuill 
                    ref={(el) =>{this.quillRef = el}}
                    onChange={this.handleContent}  
                    modules={this.state.modules}
                    selection={{start:0, end:0}}
                    formats={this.formats} 
                    value={this.state.content}
                /> 
            </div>
        );
    }

}

export default AdminQuill;