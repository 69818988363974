import React, { Fragment, useEffect, useReducer, useState } from 'react';
import Service from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';

import 'react-quill/dist/quill.snow.css';
import ProductCategories from './AdminProductCategories';
import {NotificationManager} from 'react-notifications';
import T from '../components/Translate';
import Media from '../components/Media';
import AdminQuill from '../components/AdminQuill';
import { withRouter, Link } from 'react-router-dom';

const initialState = {
    name: '',
    slug: '',
    sku: '',
    shortDescription: '',
    weight: '', 
    height: '',
    width: '',
    length: '',
    sizeUnit: '',
    weightUnit: '',
    price: '',
    discount: '',
    quantityStatus: '',
    quantity: '',
    type: '',
    image: '',
    manageStock: '',
    stock: ''
};

function reducer(state, action) {
    switch (action.type) {
        case action.type:
            {
                return {
                    ...state,
                    [action.type]: action.initialState,
                  };
            }
        default:
            throw new Error();
    }
}

function AdminProduct(props){
    const { id, handlePageId } = props.match.params;
    const [product, dispatch] = useReducer(reducer, initialState);
    const [isUpdate, setIsUpdate] = useState(false);
    const [productImages, setProductImages] = useState([]);
    const [thumbnailImageVisible, setThumbnailImageVisible] = useState(false);
    const [productImagesVisible, setProductImagesVisible] = useState(false);
    const [description, setDescrption] = useState('');
    const [status, setStatus] = useState('');
    const [featured, setFeatured] = useState('');
    const [productImage, setProductImage] = useState('');
    const [gallery, setGallery] = useState([]);

    useEffect(()=>{
        if(id){
            getProduct();
            getProductImage();
            getGallery();
        }
    },[])

    const getProduct=()=>{
        Service.get("/products/single",{productId: id}, (res) => {
            dispatch({type: 'name', initialState: res.product.name})
            dispatch({type: 'slug', initialState: res.product.slug})
            dispatch({type: 'sku', initialState: res.product.sku})
            dispatch({type: 'shortDescription', initialState: res.product.short_description})
            dispatch({type: 'weight', initialState: res.product.weight})
            dispatch({type: 'height', initialState: res.product.height})
            dispatch({type: 'width', initialState: res.product.width})
            dispatch({type: 'length', initialState: res.product.length})
            dispatch({type: 'sizeUnit', initialState: res.product.size_unit})
            dispatch({type: 'weightUnit', initialState: res.product.weight_unit})
            dispatch({type: 'price', initialState: res.product.price})
            dispatch({type: 'discount', initialState: res.product.discount})
            dispatch({type: 'quantityStatus', initialState: res.product.quantity_status})
            dispatch({type: 'manageStock', initialState: res.product.manage_stock})
            dispatch({type: 'quantity', initialState: res.product.quantity})
            dispatch({type: 'type', initialState: res.product.type})
            dispatch({type: 'thumbnailUrl', initialState: res.product.image})
            dispatch({type: 'image', initialState: res.product.image_id})
            setProductImages([]);
            setIsUpdate(true);
            setDescrption(res.product.description);
            setStatus(res.product.status);
            setFeatured(res.product.featured);
        });
    }

    const createProduct=()=>{
        console.log(product);
        //return;
        Service.get("/products/create",{
            productId: id,
            name: product.name,
            slug: product.slug,
            sku: product.sku,
            shortDescription: product.shortDescription,
            description,
            weight: product.weight, 
            height: product.height,
            width: product.width,
            length: product.length,
            sizeUnit: product.sizeUnit,
            weightUnit: product.weightUnit,
            price: product.price,
            discount: product.discount,
            status,
            featured,
            quantityStatus: product.quantityStatus,
            quantity: product.quantity,
            type: product.type,
            image: product.image,
            manageStock: product.manageStock,
            stock:  product.stock
        }, (res) => {
            if(res.success){
                console.log('success', res);
                // window.location.assign(`/product/${res.lastInsertedId}`);
                props.history.push(`/product/${res.lastInsertedId}`);
                setIsUpdate(true)
                //NotificationManager.success(ta("successMessageUpdateProduct"))
                //getProduct();
            }
        });
    
    }

    const updateProduct=()=>{
        Service.get("/products/update",{
            productId: id,
            name: product.name,
            slug: product.slug,
            sku: product.sku,
            shortDescription: product.shortDescription,
            description,
            weight: product.weight, 
            height: product.height,
            width: product.width,
            length: product.length,
            sizeUnit: product.sizeUnit,
            weightUnit: product.weightUnit,
            price: product.price,
            discount: product.discount,
            status,
            featured,
            quantityStatus: product.quantityStatus,
            quantity: product.quantity,
            type: product.type,
            image: product.image,
            manageStock: product.manageStock
        }, (res) => {
            if(res.success){
                NotificationManager.success(<T text="successMessageUpdateProduct"/>)
                getProduct();
            }
        });
    }

    const updateProductValue=(e)=>{
        dispatch({type: e.target.id, initialState: e.target.value})
    }

    const setProductImageFunc=(image)=>{
        Service.get('/products/setProductImage',{imageId: image.id, productId: id}, res => {
            if(res.success){
                NotificationManager.success(<T text="successMessageSetProductImage"/>)
                getProductImage();
                dispatch({type: 'image', initialState: image.id})
            }
        })
    }

    const changeProductStatus=(e)=>{
        Service.get("/products/setStatus",{
            productId: id,
            status: e.target.checked ? 1 : 0
        }, (res) => {
            if(res.success){
                getProduct();
            }
        });
    }

    const setProductFeatured=(e)=>{
        Service.get("/products/setFeatured",{
            productId: id,
            featured: e.target.checked ? 1 : 0
        }, (res) => {
            if(res.success){
                getProduct();
            }
        });
    }

    const getProductImage=()=>{
        Service.get("/products/getProductImage",{
            productId: id
        }, (res) => {
            setProductImage(res.image);
        });
    }

    // Quill content from component
    const setQuillContent=(data)=>{
        setDescrption(data);
    }

    // Gallery
    const getGallery=()=>{
        Service.get("/products/getGallery",{
            productId: id
        }, (res) => {
            setGallery(res.images)
        });
    }

    const setProductGallery=(images)=>{
        Service.get("/products/setGallery",{
            productId: id,
            gallery: images
        }, (res) => {
            if(res.success){
                NotificationManager.success(<T text="successMessageSetProductGallery"/>)
                getGallery()
            }
        });
    }

    const removeGalleryImage=(e)=>{
        let imageId = e.target.getAttribute('data-image-id');
        Service.get('/products/removeGalleryImage',{imageId, productId: id},(res)=>{
            if(res.success){
                getGallery();
            }
        })
    }

    const moveGalleryImage=(e)=>{
        Service.get('/products/galleryImagePosition',{productId: id, imageId: e.target.dataset.id, direction: e.target.dataset.move},(res)=>{
            getGallery();
        });
    }

    return(
        <div className="admin">
            <HeaderAdmin />
            <div className="admin-content">
            
                {thumbnailImageVisible &&
                    <Media className="media-module" onCancel={()=>{setThumbnailImageVisible(false)}} onSelect={(image)=>{setProductImageFunc(image); setThumbnailImageVisible(false)}} />
                }

                {productImagesVisible &&
                    <Media className="media-module" multi={true} onCancel={()=>{setProductImagesVisible(false)}} onSelect={(image)=>{setProductGallery(image); setProductImagesVisible(false)}} />
                }

                <label><T text="name"/></label>
                <input type="text" id="name" className="admin-product-input-name" value={product.name || ''} onChange={(e)=>{updateProductValue(e)}}/>
                <label>SKU</label>
                <input type="text" id="sku" className="admin-product-input-name" value={product.sku || ''} onChange={(e)=>{updateProductValue(e)}}/>
                <label><T text="slug"/></label>
                <input type="text" id="slug" value={product.slug || ''} onChange={(e)=>{dispatch({type: 'slug', initialState: e.target.value.replace(/[^0-9a-zA-Z\/-\s]/g,'').replace(/\s/g, '').replace(/\//g, '')})}}/>
                <label><T text="shortDescription"/></label>
                <textarea type="text" id='shortDescription' value={product.shortDescription || ''} onChange={(e)=>{updateProductValue(e)}}></textarea>
            
                <AdminQuill content={ description } onChange={setQuillContent}/>

                <div className="half">
                    <h3><T text="dimensions"/></h3>
                    <label><T text="height"/></label>
                    <input type="text" id="height" value={product.height || ''} onChange={(e)=>{updateProductValue(e)}} />
                    <label><T text="width"/></label>
                    <input type="text" id="width" value={product.width || ''} onChange={(e)=>{updateProductValue(e)}} />
                    <label><T text="length"/></label>
                    <input type="text" id="length" value={product.length || ''} onChange={(e)=>{updateProductValue(e)}} />
                    <label><T text="sizeUnit"/></label>
                    <input type="text" id="sizeUnit" value={product.sizeUnit || ''} onChange={(e)=>{updateProductValue(e)}} />
                    <hr />
                    <label><T text="weight"/></label>
                    <input type="text" id="weight" value={product.weight || ''} onChange={(e)=>{updateProductValue(e)}} />
                    <label><T text="weightUnit"/></label>
                    <input type="text" id="weightUnit" value={product.weightUnit || ''} onChange={(e)=>{updateProductValue(e)}} />
                </div>

                <div className="half">
                    <h3><T text="sale"/></h3>
                    <label><T text="price"/></label>
                    <input type="text" id="price" value={product.price || ''} onChange={(e)=>{updateProductValue(e)}} />
                    <label><T text="discount"/></label>
                    <input type="text" id="discount" value={product.discount || ''} onChange={(e)=>{updateProductValue(e)}} />
                    <label><T text="quantity"/></label>
                    <input type="text" id="quantity" value={product.quantity || ''} onChange={(e)=>{updateProductValue(e)}} />
                    <div className="admin-product-active">
                        <input type="checkbox" checked={!!product.quantityStatus} onChange={(e)=>{dispatch({type: 'quantityStatus', initialState: e.target.checked ? 1 : 0})}}/>
                        <label>OOS</label>
                        <div className="tooltip">
                            <i className="fas fa-info-circle"></i>                            
                            <span className="tooltiptext">Manage stock text</span>
                        </div>
                    </div>
                    <div className="admin-product-active">
                        <input type="checkbox" checked={!!product.manageStock} onChange={(e)=>{dispatch({type: 'manageStock', initialState: e.target.checked ? 1 : 0})}}/>
                        <label>Manage stock</label>
                        <div className="tooltip">
                            <i className="fas fa-info-circle"></i>                            
                            <span className="tooltiptext">Manage stock text</span>
                        </div>
                    </div>
                </div>

                {(isUpdate === true && productImages.length > 0) &&
                    <div>
                        <label>Slike proizvoda:</label>
                        {productImages.map(function(item, index){
                                return(
                                    <div className="admin-media" key={index}>
                                        <img className="admin-media-img" src={item.image}  alt={item.file_name} /> 
                                    </div>
                                )
                            })
                        }
                    </div>
                }

                {!isUpdate &&
                    <div className="button" onClick={()=>{createProduct()}}><T text="saveProduct"/></div>
                } 

                {/* controls */}
                <div className="admin-controls">
                    {isUpdate &&
                        <Fragment>
                            {/* Update button */}
                            <div className="button" onClick={()=>{updateProduct()}}><T text="saveChanges"/></div>
                            
                            {/* Status */}
                            <div className="admin-product-active">
                                <input type="checkbox" checked={!!status} onChange={(e)=>{changeProductStatus(e)}}/>
                                <label><T text="active"/></label>
                            </div>

                            {/* Featured */}
                            <div className="admin-product-featured">
                                <input type="checkbox" checked={!!featured} onChange={(e)=>{setProductFeatured(e)}}/>
                                <label><T text="shownAtHome"/></label>
                            </div>
                            <hr />

                            {/* Product image */}
                            <h3><T text="productImage"/></h3>
                            <div className="admin-product-image">
                                <img className="product-thumbnail" src={productImage} onClick={()=>{setThumbnailImageVisible(true)}}/>
                                <div className="button" onClick={()=>{setThumbnailImageVisible(true)}}><T text="changeImage"/></div>
                            </div>
                            <hr />

                            {/* Gallery */}
                            <h3><T text="additionalImages"/></h3>
                            <div className="admin-product-gallery">
                                {gallery.map(function(item, index ){
                                    return(
                                        <span className="admin-product-gallery-image-container" key={index}>
                                            <img className="product-gallery-thumbnail" src={item.image}/>
                                            {/* First image */}
                                            {(index === 0 && gallery.length !== index + 1) &&
                                                <Fragment>
                                                    <img data-image-id={item.id} onClick={(e)=>{removeGalleryImage(e)}} className="product-gallery-delete" src="/images/trash.png" />
                                                    <img className="product-gallery-move-right" src="/images/arrow-right.png" onClick={(e)=>{moveGalleryImage(e)}} data-id={item.id} data-move="1" data-index={index} />
                                                </Fragment>
                                            }
                                            {/* Middle images */}
                                            {(index !== 0 && gallery.length !== index + 1) &&
                                                <Fragment>
                                                    <img className="product-gallery-move-left" src="/images/arrow-left.png" onClick={(e)=>{moveGalleryImage(e)}} data-id={item.id} data-move="-1" data-index={index} />
                                                    <img data-image-id={item.id} onClick={(e)=>{removeGalleryImage(e)}} className="product-gallery-delete" src="/images/trash.png" />
                                                    <img className="product-gallery-move-right" src="/images/arrow-right.png" onClick={(e)=>{moveGalleryImage(e)}} data-id={item.id} data-move="1" data-index={index}/>
                                                </Fragment>
                                            }
                                            {/* Last image */}
                                            {(index !== 0 && gallery.length === index + 1) &&
                                                <Fragment>
                                                    <img className="product-gallery-move-left" src="/images/arrow-left.png" onClick={(e)=>{moveGalleryImage(e)}} data-id={item.id} data-move="-1" data-index={index}/>
                                                    <img data-image-id={item.id} onClick={(e)=>{removeGalleryImage(e)}} className="product-gallery-delete" src="/images/trash.png" />
                                                </Fragment>
                                            }
                                            {(index === 0 && gallery.length === index + 1) &&
                                                <Fragment>
                                                    <img data-image-id={item.id} onClick={(e)=>{removeGalleryImage(e)}} className="product-gallery-delete" src="/images/trash.png" />
                                                </Fragment>
                                            }
                                        </span>
                                    )
                                })}
                                <div className="button" id="uploadImagesVisible" onClick={()=>{setProductImagesVisible(true)}}><T text="addImage"/></div>
                                <hr />
                                
                                {/* Categories */}
                                <h3><T text="categories"/></h3>
                                <ProductCategories productId={id} />
                            </div>
                        </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default withRouter(AdminProduct);
