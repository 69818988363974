import React, { useEffect, useState } from 'react';
import ServiceAdmin from '../components/ServiceAdmin';
export const StoreContext = React.createContext();
export const StoreProvider = props => {
  const[store, setStore] = useState({})
  useEffect(()=>{
    // ServiceAdmin.get('/store/get', {}, (res)=>{setStore(res.data)})
  }, []);
  const contextValue = {
    store
  };

  return (
    <StoreContext.Provider value={contextValue}>{props.children}</StoreContext.Provider>
  );
};