import React, { useEffect, useState } from 'react';
import Service from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';
import { NotificationManager } from 'react-notifications';
import T from '../components/Translate';

export default function AdminDocuments(){
    const [documents, setDocuments] = useState([]);

    useEffect(()=>{
        getDocuments();
    },[]);

    const getDocuments = () => {
        Service.get("/documents/get",{}, (res) => {
            setDocuments(res.documents);
        });
    }

    const upload = (event) => {
        const files = Array.from(event.target.files)
        console.log("files", files)
        const formData = new FormData()
        files.forEach((file, i) => {
            formData.append(i, file)
        })
        console.log('formData:', formData);
        Service.uploadDocument(formData,(res)=>{
            getDocuments();
        });
    }

    return(
        <div className="admin">
            <HeaderAdmin />
            <div className="admin-content">
                <label htmlFor="multi" className="send-file-label" id="send-file-button">Upload file<span className="mandatory-field">*</span></label>
                <input type='file' id='multi' name="userImage[]" onChange={upload} multiple/>  
                <div className="admin-media">
                {documents.map(function(item, index){
                        return(
                            <p className='document-item'>{item}</p>
                        )
                    })
                }
                </div>
            </div>
        </div>
    )
}