import React, { Component } from 'react';
import ServiceAdmin from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { NotificationManager } from 'react-notifications';
import T from '../components/Translate';

class AdminThemes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            themes: [],
            currentTheme: '',
            currentPreview: ''
        }
    }

    componentDidMount(){
        this.getThemes();
        this.getCurrentTheme();
    }

    getThemes=()=>{
        ServiceAdmin.get('/admin/themes/get',{}, res => {
            if(res.success){
                this.setState({themes: res.themes})
            }
        })
    }

    applyTheme=(themeId)=>{
        ServiceAdmin.get('/admin/themes/apply',{themeId}, res => {
            if(res.success){
                NotificationManager.success(<T text="successMessageApplyTheme"/>);
                this.getCurrentTheme();
            }
        })
    }

    previewTheme=(themeId)=>{
        ServiceAdmin.get('/admin/themes/preview',{themeId}, res => {
            if(res.success){
                this.getCurrentTheme();
            }
        })
    }

    getCurrentTheme=()=>{
        ServiceAdmin.get('/admin/themes/getCurrentTheme',{}, res => {
            if(res.success){
                this.setState({
                    currentTheme: res.theme[0].theme,
                    currentPreview: res.theme[0].preview_theme
                })
            }
        })
    }

    render() {
        return(
            <div className="admin">
            <HeaderAdmin />
                <div className="admin-content">
                    <h1><T text="themes"/></h1>
                    
                    <table>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th><T text="name"/></th>
                                <th><T text="action"/></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.themes.map(function(item, index){
                                    return(
                                        <tr key={index}>
                                            <td>{item.theme_id}</td>
                                            <td>{item.name}</td>
                                            <td>
                                                <div className="button" onClick={() =>this.applyTheme(item.theme_id)}><T text="apply"/> {this.state.currentTheme && this.state.currentTheme === item.theme_id ? '(current)' : null}</div>
                                                <div className="button" onClick={() =>this.previewTheme(item.theme_id)}><T text="preview"/> {this.state.currentPreview && this.state.currentPreview === item.theme_id ? '(current)' : null}</div>
                                            </td>
                                        </tr>
                                    )
                                },this)
                            }
                        </tbody>
                    </table>
                </div>

                {/* controls */}
                <div className="admin-controls">

                </div>
            </div>
        );
    }

}

export default AdminThemes;