import React, { Component } from 'react';
import Service from '../components/ServiceAdmin';
import T from '../components/Translate';


class CategoriesSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            selectedCategory: '',
            selectedCategories: [],
            createCategoryVisible: false,
            categoryName: '',
            categoryDescription: ''
        }
    }

    componentDidMount(){
        this.getCategories();
        this.getSelectedCategory();
    }

    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value});
    }

    getCategories=()=>{
        Service.get("/categories/get",{},(res) => {
            this.setState({categories: res.categories})
        });
    }

    setCategory=(event)=>{
        let key = '';
        this.props.source === 'posts' ? key = 'postId' : key = 'pageId';

        Service.get(`/${this.props.source}/setCategory`,{
            [key]: this.props.id,
            categoryId: event.target.value
        },(res) => {
            if(res.success){
                this.getCategories();
                this.getSelectedCategory();
            }
        });
    }

    getSelectedCategory=()=>{
        let key = '';
        this.props.source === 'posts' ? key = 'postId' : key = 'pageId';

        Service.get(`/${this.props.source}/categories`,{
            [key]: this.props.id
        },(res) => {
            this.setState({selectedCategories: res.categories})
        });
    }

    createCategoryVisible=()=>{
        this.setState({createCategoryVisible: !this.state.createCategoryVisible})
    }

    createCategory=()=>{
        Service.get("/categories/addCategory",{
            name: this.state.categoryName,
            description: this.state.categoryDescription,
            status: 1,
            parentId: 0
        },(res) => {
            if(res.success){
                this.setState({createCategoryVisible: false, categoryName:'', categoryDescription:''},()=>{
                    this.getCategories();
                })
            }
        });
    }

    render() {
        return(
            <div className="admin-categories">
                    <ul className="admin-product-categories">
                    {this.state.categories.map(function(item, index){
                        let checked = false;
                        const findChecked = this.state.selectedCategories.find(el => el.id === item.id);
                        if(findChecked){
                            checked = true;
                        }
                        return(
                            <li key={index}>                       
                                <input id={item.id} type="checkbox" value={item.id} checked={checked} onChange={this.setCategory}/>  
                                <label for={item.id}>{item.name}</label>                  
                            </li>
                        )},this)}
                        <li className="button-category-add" onClick={this.createCategoryVisible}>
                            + <T text="addCategory"/>
                            {/* <div className="button admin-add-category-button" onClick={this.createCategoryVisible}>Dodaj kategoriju</div> */}
                        </li>
                    </ul>
                    
                    {this.state.createCategoryVisible === true &&
                        <div>
                            <label htmlFor="categoryName"><T text="name"/></label>
                            <input id="categoryName" type="text" value={this.state.categoryName} onChange={this.handleInput} />

                            <label htmlFor="categoryDescription"><T text="description"/></label>
                            <input id="categoryDescription" type="text" value={this.state.categoryDescription} onChange={this.handleInput} />
                            <div className="button" onClick={this.createCategory}><T text="upload"/></div>
                        </div>
                    }
                    
            </div>
        );
    }

}

export default CategoriesSelect;