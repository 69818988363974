import React, {useState, useEffect, Fragment} from 'react';
import ServiceAdmin from '../components/ServiceAdmin';
import Service from '../components/Service';
import {Auth} from '../components/Auth';

export default function Media(props) {
    const [image, setImage] = useState();
    const [images, setImages] = useState([]);
    const [selected, setSelected] = useState([]);
    const [previewImage, setPreviewImage] = useState({action: false, img: ''});
    const [filter, setFilter] = useState('');

    const getImages=()=>{
        Service.get('/media/get', {filter}, (res)=>{
            setImages(res.media);
        })
    }

    useEffect(() => {
        getImages();
    }, []);

    
    useEffect(() => {
        console.log("ef item", selected)
    }, [selected]);

    const uploadImage = (event) => {
        const files = Array.from(event.target.files)
        const formData = new FormData()

        files.forEach((file, i) => {
            formData.append(i, file)
        })
        
        ServiceAdmin.uploadImage(formData,(res)=>{
            getImages();
        });
    }

    const selectImage=(item)=>{
        let s = [];
        let existing = false;
        selected.map(i=>{
            s.push(i)
        })

        if(props.multi){
            s.map((image, index)=>{
                if(image.id === item.id){
                    // Remove from selected
                    s.splice(index, 1);
                    existing = true;
                }
            })
    
            if(!existing){
                s.push(item);
            }    
        }else{
            s = [item];
        }
        
        setSelected(s)
    }

    const submitImages=()=>{
        let si = [];

        selected.map(item=>{
            si.push(item.id)
        })

        if(props.multi){
            props.onSelect(si)
        }else{
            props.onSelect(selected[selected.length - 1])
        }
        
    }

    return (
        <div className={props.className}>
            <div className="head">
                <h3 className="align-center">Media</h3>
                <label htmlFor="multi" className="btn">Aplouduj sliku</label>
                <input type='file' id='multi' name="userImage[]" onChange={uploadImage} multiple data-buttonText={"Your label here."} />
                <h3>Odaberi sliku:</h3>
            </div>  

            <div className="body">
                <div className={selected.length > 0 ?'media-images half' : 'media-images'}>
                    {images.map(item => {
                        return(
                            <Fragment>
                                {/* {item.thumbnail ? 
                                    <img key={item.id} style={selected.includes(item) ? {border: '3px solid black'} : {}} src={item.thumbnail} onClick={()=>{selectImage(item)}} title="Klikni da odaberes sliku"/>
                                :
                                    <img key={item.id} style={selected.includes(item) ? {border: '3px solid black'} : {}} src={item.url} onClick={()=>{selectImage(item)}} title="Klikni da odaberes sliku"/>
                                } */}
                                <img key={item.id} style={selected.includes(item) ? {border: '3px solid black'} : {}} src={item.url} onClick={()=>{selectImage(item)}} title="Klikni da odaberes sliku"/>
                            </Fragment>
                        )
                    })
                    }
                </div>
                {selected.length > 0 &&
                    <div className='half selected-media-image'>
                        <img className="half-img" src={selected[selected.length - 1].url} />
                    </div>
                }
            </div>


           

            <div className="media-footer">
                {/* <button  onClick={()=>{props.multi ? props.onSelect(selected) : props.onSelect(image)}} >Izaberi</button> */}
                <button  onClick={submitImages} >Izaberi</button>
                <button className="button-cancel" onClick={props.onCancel}>Odustani</button>
            </div>
        
        </div>
    )
}
