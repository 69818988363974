import React, { useEffect, useState } from 'react';
import Service from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';
import { NotificationManager } from 'react-notifications';
import T from '../components/Translate';
import Media from '../components/Media';
import AdminQuill from '../components/AdminQuill';
import DateTime from '../components/DateTime';
import CategoriesSelect from '../components/CategoriesSelect';

export default function PostSingle(props){
    const { id, handlePostId } = props.match.params;
    const [title, setTitle] = useState('');
    const [postDate, setPostDate] = useState('');
    const [description, setDescription] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [content, setContent] = useState('');
    const [slug, setSlug] = useState('');
    const [featured, setFeatured] = useState('');
    const [featuredImage, setFeaturedImage] = useState('');
    const [author, setAuthor] = useState('');
    const [categories, setCategories] = useState('');
    const [status, setStatus] = useState(0);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isMediaFeaturedImage, setIsMediaFeaturedImage] = useState(false);

    useEffect(()=>{
        if(id){
            getPost();
            setIsUpdate(true);
        }
    },[])
    
    const getPost=()=>{
        Service.get("/posts/get",{postId: id}, (res) => {
            if(res.success){
               setTitle(res.post.title);
               setSlug(res.post.slug);
               setPostDate(res.post.post_date);
               setShortDescription(res.post.short_description);
               setDescription(res.post.description);
               setContent(res.post.content);
               setStatus(res.post.status);
               setAuthor(res.post.author);
               setFeaturedImage(res.post.image);
               setFeatured(res.post.featured);
            }
        });
    }

    const createPost=()=>{
        Service.get("/posts/create",{
           title,
           slug,
           postDate,
           shortDescription,
           description,
           content,
           author
        },(res) => {
            if(res.success){
                NotificationManager.success(<T text="successMessageCreatePost"/>)
                window.location.href= '/post/'+res.lastInsertedId;
            }
        });
    }

    const updatePost=()=>{
        Service.get("/posts/update",{
            postId: id,
            title,
            slug,
            postDate,
            shortDescription,
            description,
            content,
            author
        }, (res) => {
            if(res.success){
                NotificationManager.success(<T text="successMessageUpdatePage"/>)
                getPost();
            }
        });
    }

    const changePostStatus=(e)=>{
        Service.get("/posts/setStatus",{
            postId: id,
            status: e.target.checked ? 1 : 0
        }, (res) => {
            if(res.success){
                getPost();
            }
        });
    }

    const changeFeatured=(e)=>{
        Service.get("/posts/setFeatured",{
            postId: id
        }, (res) => {
            if(res.success){
                getPost();
            }
        });
    }

    const removePost=()=>{
        Service.get("/posts/setStatus",{
            postId: id,
            status: 9
        }, (res) => {
            if(res.success){
                if (window.confirm("Are you sure want to delete this post?") == true) {
                    window.location.href= '/posts';
                }
            }
        });
    }

    // Featured image
    const setFeaturedImageFunc=(image)=>{
        Service.get('/posts/setImage',{
            postId: id,
            imageId: image.id
        }, res => {
            if(res.success){
                NotificationManager.success(<T text="successMessageSetPageFeaturedImage"/>)
                getPost();
            }
        })
    }

    const setQuillDescription=(data)=>{
        setDescription(data)
    }

    const setQuillContent=(data)=>{
        setContent(data)
    }

    const handleFromDateChange=(date)=>{
        setPostDate(date)
    }

    return(
        <div className="admin">
        <HeaderAdmin />
            <div className="admin-content">
                <h1>{title}</h1>

                <label><T text="title"/></label>
                <input type="text" value={title} onChange={(e)=>{setTitle(e.target.value)}} />

                <label><T text="slug"/></label>
                <input type="text" value={slug} onChange={(e)=>{setSlug(e.target.value.replace(/[^0-9a-zA-Z\/-\s]/g,'').replace(/\s/g, '').replace(/\//g, ''))}} />
                
                <label><T text="author"/></label>
                <input type="text" value={author} onChange={(e)=>{setAuthor(e.target.value)}} />

                <label>Post date</label>
                <DateTime placeholder="Date" text="Izaberite datum" selected={postDate} showTimeSelect onChange={handleFromDateChange}/>

                <label><T text="shortDescription"/></label>
                <input type="text" value={shortDescription} onChange={(e)=>{setShortDescription(e.target.value)}} />

                {/* Quill */}
                <AdminQuill label={'Decription'} content={ description } onChange={setQuillDescription}/>

                <AdminQuill label={'Content'} content={ content } onChange={setQuillContent}/>

                {!isUpdate &&
                    <div className="button" onClick={()=>{createPost()}}><T text="submit"/></div>
                }

                {isMediaFeaturedImage === true &&
                    <Media className="media-module" onCancel={()=>{setIsMediaFeaturedImage(false)}} onSelect={(image)=>{setFeaturedImageFunc(image); setIsMediaFeaturedImage(false)}} />
                }
            </div>
        

            <div className="admin-controls">
                {isUpdate &&
                    <div>
                        <div className="button" onClick={()=>{updatePost()}}><T text="saveChanges"/></div>

                        <div className="admin-page-active">
                            <input type="checkbox" checked={ status } onChange={(e)=>{changePostStatus(e)}}/>
                            <label><T text="status"/></label>
                        </div>

                        <div className="admin-page-active">
                            <input type="checkbox" checked={ featured } onChange={(e)=>{changeFeatured(e)}}/>
                            <label><T text="featured"/><div className='help'><T text="helpFeatured"/></div></label>
                        </div>

                        <h3><T text="featuredImage"/></h3>
                        <div className="admin-product-image">
                            {featuredImage ?
                            <img className="product-thumbnail" src={featuredImage} onClick={()=>{setIsMediaFeaturedImage(true)}}/>
                            : 
                            <p>No image selected</p>
                            }
                            <div className="button" onClick={()=>{setIsMediaFeaturedImage(true)}}><T text="changeImage"/></div>
                        </div>

                        <hr /> 
                        {/* Categories */}
                        <h3><T text="categories"/></h3>
                        <CategoriesSelect id={id} source={'posts'}/>

                        <hr />
                        <div className="admin-page-delete">
                            <div className="button" onClick={()=>{removePost()}}>Delete post</div>
                        </div> 
                    </div>
                }
            </div>
        </div>
    )
}