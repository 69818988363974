import React, { Component } from 'react';
import Service from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';
import T from '../components/Translate';
import { NotificationManager } from 'react-notifications';
import AdminQuill from '../components/AdminQuill';
import { ReactComponent as Delete } from '../icons/trash.svg';

class AdminForumSingle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: '',
            status: 0,
            comments: [],
            isEditTopic: false
        }
    }

    componentDidMount() {
        if(this.props.match.params.id){
            this.getForumTopic();
            this.getTopicComments();
        }
    }

    getForumTopic=()=>{
        Service.get("/forum/get",{
            forumId: this.props.match.params.id
        },(res) => {
            if(res.success){
                this.setState({
                    title: res.forum.title,
                    description: res.forum.description,
                    status: res.forum.status
                })
            }
        });
    }

    getTopicComments=()=>{
        Service.get("/forum/getComments",{
            forumId: this.props.match.params.id
        },(res) => {
            if(res.success){
                this.setState({comments: res.comments})
            }
        });
    }
   
    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value});
    }

    setQuillDescription=(data)=>{
        this.setState({description: data})
    }

    setForumStatus=(event)=>{
        this.setState({status: event.target.checked ? 1 : 0})
    }

    updateTopic=()=>{
        Service.get("/forum/updateForumTopic",{
            title : this.state.title,
            description: this.state.description,
            status: this.state.status,
            forumId: this.props.match.params.id
        },(res) => {
            if(res.success){
                this.getForumTopic();
                NotificationManager.success(<T text="successMessageUpdateForumTopic"/>)
            }
        });
    }

    deleteComment=(id)=>{
        if(!window.confirm('Are you sure that you want to delete this comment?')){
            return;
        }

        Service.get("/forum/removeComment",{
            commentId: id,
        },(res) => {
            if(res.success){
                this.getTopicComments();
            }
        });
    }

    render() {
        return(
            <div className="admin">
                <HeaderAdmin />
                <div className="admin-content">
                    <h1>Topic #{this.props.match.params.id}</h1>

                    <label><T text="title"/></label>
                    <input type="text" id="title" value={this.state.title} onChange={this.handleInput} />

                    <AdminQuill label={'Decription'} content={ this.state.description } onChange={this.setQuillDescription}/>

                    <label>Status</label>
                    <input type="checkbox" data-topic-id={this.state.id} checked={this.state.status} onChange={this.setForumStatus}/>

                    <div className="button" onClick={()=>{this.updateTopic()}}><T text="submit"/></div>
                  
                    <h2>Comments</h2>
                    {this.state.comments.map((item)=>{
                        return(
                            <div className="forum-comment">
                                <time>{item.name + ' ' +item.formatted_date}</time>
                                <p>{item.text}</p>
                                <Delete className="delete-forum-comment" onClick={()=>{this.deleteComment(item.id)}} />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

}

export default AdminForumSingle;