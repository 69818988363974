import React, { Component, Fragment } from 'react';
import ServiceAdmin from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {NotificationManager} from 'react-notifications';
import T from '../components/Translate';
import CommonContent from '../components/CommonContentJson';

class AdminCommonContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            termsOfUseSr: '',
            termsOfUseEn: '',
            privacyPolicyEn: '',
            privacyPolicySr: '',
            modules: {
                clipboard: {
                    matchVisual: false,
                },
                toolbar: {
                    container: [
                        [{ 'header': [false, 2, 3] }],
                        [{ 'align': [] }],
                        ['bold', 'italic', 'underline',],
                        [{'list': 'ordered'}, {'list': 'bullet'}],
                        [{ 'indent': '-1'}, { 'indent': '+1' }],
                        [{ 'script': 'super' }, 'strike'],
                        [{ 'color': [] }], 
                        ['link']
                    ],
                }
            }
        }

        this.formats = [
            'header',
            'align',
            'bold', 'italic', 'underline', 
            'list', 'bullet',
            'indent', 'indent',
            'script', 'strike',
            'color',
            'link'
        ];
    }

    componentDidMount(){
        this.getCommonContent();
    }

    getCommonContent=()=>{
        ServiceAdmin.get("/store/getCommonContent",{},(res) => {
            if(res.success){
                if(res.content[0].terms_of_use_en){
                    this.setState({termsOfUseEn: res.content[0].terms_of_use_en})
                }else{
                    this.setState({termsOfUseEn: CommonContent.termsOfUseEn})
                }
                if(res.content[0].terms_of_use_sr){
                    this.setState({termsOfUseSr: res.content[0].terms_of_use_sr})
                }else{
                    this.setState({termsOfUseSr: CommonContent.termsOfUseSr})
                }
                if(res.content[0].privacy_policy_en){
                    this.setState({privacyPolicyEn: res.content[0].privacy_policy_en})
                }else{
                    this.setState({privacyPolicyEn: CommonContent.privacyPolicyEn})
                }
                if(res.content[0].privacy_policy_sr){
                    this.setState({privacyPolicySr: res.content[0].privacy_policy_sr})
                }else{
                    this.setState({privacyPolicySr: CommonContent.privacyPolicySr})
                }
            }else{
                if(res.info === 'no data'){
                    this.setNoDataCommonContent();
                }
            }
        });
    }

    setNoDataCommonContent=()=>{
        ServiceAdmin.get("/admin/setCommonContent",{
            termsOfUseEn: CommonContent.termsOfUseEn,
            termsOfUseSr: CommonContent.termsOfUseSr,
            privacyPolicyEn: CommonContent.privacyPolicyEn,
            privacyPolicySr: CommonContent.privacyPolicySr
        },(res) => {
            if(res.success){
                this.getCommonContent();
            }
        });
    }

    handleTermsOfUseEn=(value)=>{
        this.setState({ termsOfUseEn: value })
    }

    handleTermsOfUseSr=(value)=>{
        this.setState({ termsOfUseSr: value })
    }

    handlePrivacyPolicyEn=(value)=>{
        this.setState({ privacyPolicyEn: value })
    }

    handlePrivacyPolicySr=(value)=>{
        this.setState({ privacyPolicySr: value })
    }

    setCommonContent=()=>{
        ServiceAdmin.get("/store/setCommonContent",{
            termsOfUseEn: this.state.termsOfUseEn,
            termsOfUseSr: this.state.termsOfUseSr,
            privacyPolicyEn: this.state.privacyPolicyEn,
            privacyPolicySr: this.state.privacyPolicySr
        },(res) => {
            if(res.success){
                NotificationManager.success(<T text="successMessageCommonContent"/>)
            }
        });
    }

    render() {
        return(
            <div className="admin">
            <HeaderAdmin />
                <div className="admin-content">
                    <h2><T text="headerTermsOfUse"/></h2>
                    <label><T text="englishVersion"/></label>
                    <ReactQuill 
                        modules={this.state.modules}
                        formats={this.formats} 
                        value={this.state.termsOfUseEn}
                        onChange={this.handleTermsOfUseEn} 
                    />

                    <label><T text="serbianVersion"/></label>
                    <ReactQuill 
                        modules={this.state.modules}
                        formats={this.formats} 
                        value={this.state.termsOfUseSr}
                        onChange={this.handleTermsOfUseSr} 
                    />

                    <h2><T text="privacyPolicy"/></h2>
                    <label><T text="englishVersion"/></label>
                    <ReactQuill 
                        modules={this.state.modules}
                        formats={this.formats} 
                        value={this.state.privacyPolicyEn}
                        onChange={this.handlePrivacyPolicyEn} 
                    />

                    <label><T text="serbianVersion"/></label>
                    <ReactQuill 
                        modules={this.state.modules}
                        formats={this.formats} 
                        value={this.state.privacyPolicySr}
                        onChange={this.handlePrivacyPolicySr} 
                    />
                </div>
                <div className="admin-controls">
                    <div className="button" onClick={this.setCommonContent}><T text="saveChanges"/></div>

                </div>
            </div>
        );
    }

}

export default AdminCommonContent;