import React, { useEffect, useState } from 'react';
import Service from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';
import T from '../components/Translate';
import Pagination from '../components/Pagination';

export default function Posts(props){
    const [posts, setPosts] = useState([]);
    const [filter, setFilter] = useState('');
    const [page, setPage] = useState(props.match.params.page || 1);
    const [totalPages, setTotalPages] = useState(0);
    const perPage = 15;

    useEffect(()=>{
        getPosts()
    },[filter])

    const getPosts=()=>{
        Service.get("/posts/get",{
            perPage,
            page: props.match.params.page,
            filter
        },(res) => {
            if(res.success){
                setPosts(res.posts);
                setTotalPages(res.total_pages);
            }
        });
    }   

    const setPostStatus=(id, e)=>{
        Service.get("/posts/setStatus",{
            postId: id,
            status: e.target.checked ? 1 : 0
        }, (res) => {
            if(res.success){
                getPosts();
            }
        });
    }

    const editPost=(id)=>{
        window.location.href= `/post/${id}`;
    }

    const addPost=()=>{
        window.location.href= '/post';
    }

    const handleFilter = (e) =>{
        setFilter(e.target.value);
    }

    return(
        <div className="admin">
        <HeaderAdmin />
            <div className="admin-content">
                <h1>Posts</h1>
                <div className="button" onClick={()=>addPost()}>Add post</div>
                <div className='search-container'><input placeholder="search..." type="text" value={filter} onChange={(e)=>{handleFilter(e)}} /></div>

                <table>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Title</th>
                            <th><T text="status"/></th>
                            <th><T text="action"/></th>
                        </tr>
                    </thead>
                    <tbody>
                        {posts.map(function(item, index){
                                return(
                                    <tr key={item.id}>
                                        <td>{item.id}</td>
                                        <td>{item.title}</td>
                                        <td><input type="checkbox" data-product-id={item.id} checked={item.status} onChange={(e)=>{setPostStatus(item.id, e)}}/></td>
                                        <td><div className="button" onClick={() =>editPost(item.id)}><T text="edit"/></div></td>
                                    </tr>
                                )
                            },this)
                        }
                    </tbody>
                </table>

                <Pagination source={'posts'} page={page} totalPages={totalPages}/>
            </div>
        

            <div className="admin-controls">
               
            </div>
        </div>
    )
}