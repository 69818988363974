import React, { useEffect, useState } from 'react';
import T from '../components/Translate';

export default function Pagination(props){
    const [previousPage, setPreviousPage] = useState(0);
    const [nextPage, setNextPage] = useState(0);

    useEffect(()=>{
        handlePages(props.page, props.totalPages);
    })


    const handlePages=(page, totalPages)=>{
        let pPage = parseInt(page) - 1;
        let nPage = parseInt(page) + 1;

        if(nPage > totalPages){
            nPage = 1;
        }
        if(pPage <= 0){
            pPage = totalPages;
        }

        setPreviousPage(pPage)
        setNextPage(nPage)   
    }

    return(
        <div className="pagination">
            <a href={`/${props.source}/${previousPage}`} ><div className="button" style={{marginRight: '.5em'}}>-</div></a>
            <T text="page"/> {props.page} / {props.totalPages}
            <a href={`/${props.source}/${nextPage}`} ><div className="button" style={{marginLeft: '.5em'}}>+</div></a>  
        </div>
    )
}