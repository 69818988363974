import React, { Component, Fragment } from 'react';
import Service from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';


class AdminVariations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isCreateAttribute: false,
            attributeName: '',
            attributeDescription: '',
            attributes: [],
            selectedAttribute:'',
            variations: [],
            variationName: '',
            isCreateVariation: false,

        }
    }

    componentDidMount() {
       this.getAttributes();
    }

    isCreateAttribute=()=>{
        this.setState({isCreateAttribute: true})
    }

    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value});
    }

    createAttribute=()=>{
        Service.get("/products/createAttribute",{
            name: this.state.attributeName,
            description: this.state.attributeDescription,
        }, (res) => {
            this.setState({isCreateAttribute: false, attributeName: '', attributeDescription: ''},()=>{
                this.getAttributes();
            })
        });
    }

    getAttributes=()=>{
        Service.get("/products/getAttributes",{
        }, (res) => {
            if(res.success){
                this.setState({attributes: res.data.attributes})
            }
        });
    }

    removeAttribute=(attributeId)=>{
        Service.get("/products/removeAttribute",{
            id: attributeId
        }, (res) => {
            this.getAttributes();
        });
    }

    getAttributeVariations=(attributeId)=>{
        this.setState({selectedAttribute: attributeId},()=>{
            Service.get("/products/getAttributeVariations",{
                attributeId: this.state.selectedAttribute
            }, (res) => {
                if(res.success){
                    this.setState({
                        variations: res.data.variations
                    })
                }
            });
        })                        
    }

    isCreateAttributeVariation=()=>{
      this.setState({isCreateVariation: true})
    }

    createAttributeVariation=()=>{
        Service.get("/products/createAttributeVariation",{
            attributeId: this.state.selectedAttribute,
            name: this.state.variationName
        }, (res) => {
           this.setState({variationName: '', isCreateVariation: false},()=>{
               this.getAttributeVariations(this.state.selectedAttribute)
           })
        });
    }

    removeAttributeVariation=(variationId)=>{
        Service.get("/products/removeAttributeVariation",{
            attributeVariation: {id : variationId}
        }, (res) => {
            this.getAttributeVariations(this.state.selectedAttribute)
        });
    }
    
    render() {
        return(
            <div className="admin">
                <HeaderAdmin />
            <div className="admin-content">
                <div className="button" onClick={this.isCreateAttribute}>Napravi novi atribut</div>
                {this.state.isCreateAttribute === true &&
                    <div>
                        <label htmlFor="attributeName">Ime</label>
                        <input id="attributeName" type="text" value={this.state.attributeName} onChange={this.handleInput} />

                        <label htmlFor="attributeDescription">Opis</label>
                        <input id="attributeDescription" type="text" value={this.state.attributeDescription} onChange={this.handleInput} />
                        <div className="button" onClick={this.createAttribute}>Napravi</div>
                    </div>
                }

                <div className="variations-content">
                    <div className="variations-left">
                        <table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Ime</th>
                                    <th>Opis</th>
                                    <th>Akcija</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.attributes.map(function(item, index){
                                        return(
                                            <tr key={index}>
                                                <td>{item.id}</td>
                                                <td>{item.name}</td>
                                                <td>{item.description}</td>
                                                <td>
                                                    <div className="button" onClick={() =>this.getAttributeVariations(item.id)}>Edituj</div>
                                                    <div className="button" onClick={() =>this.removeAttribute(item.id)}>Izbriši</div>
                                                </td>
                                            </tr>
                                        )
                                    },this)
                                }
                            </tbody>
                        </table>
                    </div>

                    {/* Variations */}
                    <div className="variations-right">
                        {this.state.selectedAttribute &&
                            <div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>#{this.state.selectedAttribute}</th>
                                            <th><div className="button" onClick={this.isCreateAttributeVariation}>Dodaj varijaciju</div></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.variations.map(function(item, index){
                                                return(
                                                    <tr key={index}>
                                                        <td>{item.name}</td>
                                                        <td>{item.description}</td>
                                                        <td>
                                                            <div className="button" onClick={() =>this.removeAttributeVariation(item.id)}>Izbriši</div>
                                                        </td>
                                                    </tr>
                                                )
                                            },this)
                                        }
                                    </tbody>
                                </table>

                                {this.state.isCreateVariation === true &&
                                    <div>
                                        <label htmlFor="variationName">Ime</label>
                                        <input id="variationName" type="text" value={this.state.variationName} onChange={this.handleInput} />
                                        <div className="button" onClick={this.createAttributeVariation}>Napravi</div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
            </div>
        );
    }

}

export default AdminVariations;